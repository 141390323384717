import {
  Box,
  Heading,
  Flex,
  HStack,
  Icon,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

//hooks
import { memo } from "react";

//icons
import { AiFillClockCircle, AiFillStar } from "react-icons/ai";
import { IoTicketSharp } from "react-icons/io5";
import { TiCancel } from "react-icons/ti";
import { ParkListLabel } from ".";
import baloon from "../../../../assets/section-water.svg";

//utils
import parkMap from "../../../../assets/park-map.jpg";

//components
import ParkInformation from "./ParkInformation";
import Wrapper from "../../../../components/Wrapper";

const PlanVisit = memo(() => {
  return (
    <>
      <Wrapper id="plan_visit">
        <SimpleGrid id="plan_visit" columns={[1, 1, 1, 2]} w="full">
          <Box borderRadius="10px" shadow="lg" p="1rem" h="full" w="full">
            <Image
              src={parkMap}
              alt="Parkmap's Image"
              w="full"
              h="full"
              fit="contain"
            />
          </Box>
          <VStack
            maxW="500px"
            spacing="10"
            p="3rem 2rem"
            w="full"
            marginLeft={{ base: 0, sm: "3.5rem" }}
            alignItems="flex-start"
          >
            <Heading
              position="relative"
              fontWeight="600"
              fontSize={{ base: "2.8rem", sm: "3.8rem" }}
              color="primary.800"
              textAlign={{ base: "center", sm: "left" }}
              _before={{
                content: `''`,
                position: "absolute",
                top: 0,
                left: "-8.5%",
                w: "50px",
                h: "50px",
                backgroundImage: baloon,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                display: { base: "none", sm: "block" },
              }}
              _after={{
                content: `''`,
                position: "absolute",
                top: "-16%",
                left: "32%",
                w: "50px",
                h: "400px",
                backgroundImage: baloon,
                transform: "rotateY(180deg) scale(.5)",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                display: { base: "none", sm: "flex" },
              }}
            >
              PLAN YOUR VISIT
            </Heading>

            <VStack
              w="full"
              alignItems="flex-start"
              as="ul"
              listStyleType="none"
              spacing={{ base: "4", lg: "6" }}
            >
              <HStack alignItems="flex-start" as="li">
                <Box
                  mt="1"
                  boxSize="10"
                  shadow="lg"
                  p=".5rem"
                  borderRadius="full"
                  bg="white"
                >
                  <Icon
                    fontSize="1.5rem"
                    color="#fc8c24"
                    as={AiFillClockCircle}
                  />
                </Box>
                <VStack alignItems="flex-start">
                  <Text
                    fontWeight="500"
                    color="#006"
                    fontSize={{ base: "1.6rem", lg: "1.6rem" }}
                    fontFamily="Berlin sans"
                  >
                    OPENING HOURS
                  </Text>
                  <Text
                    fontSize="1.3rem"
                    color="#fc8c24"
                    fontWeight="600"
                    textAlign={{ base: "center", sm: "left" }}
                  >
                    Thur - Sun and Public holidays <br /> 11am - 6pm
                  </Text>
                </VStack>
              </HStack>
              <ParkInformation
                label={ParkListLabel.TIKCET}
                icon={IoTicketSharp}
                title="TICKET PRICES"
              />
              <ParkInformation
                label={ParkListLabel.PARK}
                icon={AiFillStar}
                title="PARK RULES"
              />
              <ParkInformation
                label={ParkListLabel.PROHIBITED}
                icon={TiCancel}
                title="PROHIBITED ITEMS"
              />
            </VStack>
          </VStack>
        </SimpleGrid>
      </Wrapper>
    </>
  );
});

export default PlanVisit;
