import { RouteUrl } from "../../../utils/routeConstatnt";

export const navbarLinks = [
  {
    label: "HOME",
    url: RouteUrl.HOME,
  },
  {
    label: "ATTRACTIONS",
    url: RouteUrl.ATTRACTIONS,
  },
  {
    label: "PLAN YOUR VISIT",
    url: RouteUrl.PLAN_VISIT,
  },
  {
    label: "SERVICES",
    url: RouteUrl.SERVICES,
  },
  {
    label: "BLOG",
    url: "https://parkvegawaterpark.blogspot.com",
  },
  {
    label: "RESTURANTS",
    url: RouteUrl.FOOD,
  },
  {
    label: "TICKETS",
    url: RouteUrl.TICKETS,
    type: "button",
  },

  // {
  //   label: "LOGIN",
  //   url: RouteUrl.LOGIN
  // },
];
