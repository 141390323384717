import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import { memo } from "react";
import { socials } from "../../../../utils/socials";

const Social = memo(() => {
  return (
    <Flex
      py={{ base: "50px", sm: "100px" }}
      h="full"
      w="full"
      as="section"
      bg="#f5f8fa"
      position="relative"
      id="socials"
    >
      <Container px="2rem" w="full" centerContent maxW="1400px">
        {/* <HeadingComponent
          color="#000066"
          textAlign="center"
          fontSize={{ base: "3rem", lg: "4rem" }}
          mb="10"
        >
          CONNECT WITH US
        </HeadingComponent> */}
        <Flex alignItems="center" direction="column" maxW="400px" w="full">
          <Flex w="full" justifyContent="space-between">
            {socials.map((item, index) => (
              <Link key={index} href={item.url} h="50px">
                <Image
                  fit="contain"
                  w="full"
                  h="full"
                  src={item.icon}
                  alt="Socials Image"
                />
              </Link>
            ))}
          </Flex>
          <Text
            fontSize={"2rem"}
            fontWeight="600"
            color="primary.800"
            fontFamily={"Berlin Sans, sans-serif"}
          >
            @parkvegawaterpark
          </Text>
          <Text
            fontSize="1.4rem"
            color="#82B3C1"
            textAlign="center"
            fontFamily={"Berlin Sans, sans-serif"}
            fontWeight={"500"}
          >
            Follow our social media handles for more updates
          </Text>
        </Flex>
      </Container>
    </Flex>
  );
});

export default Social;
