export const RouteUrl = {
  HOME: "/",
  ATTRACTIONS: "/#attraction",
  LOCATIONS: "/#location",
  PLAN_VISIT: "/#plan_visit",
  SERVICES: "/#services",
  BLOG: "https://parkvegawaterpark.blogspot.com",
  TICKETS: "/ticket",
  RESTAURANT:
    "https://parkvegablobstorage.blob.core.windows.net/parkvegafoodmenu/parkvegaMenu.pdf",
  CABANAS: "/cabanas",
  EVENTS: "/events",
  HOTELS: "/hotel",
  FOOD: "https://parkvegablobstorage.blob.core.windows.net/parkvegafoodmenu/parkvegaMenu.pdf",
  CHECKOUT: "/checkout",
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/forgot-password",
  PRIVACY: "/privacy",
  TERMS: "/terms",
};
