import { Box, Heading, Image, Text, VStack } from "@chakra-ui/react";

const ServiceCard = ({ image, title, description }) => {
  return (
    <VStack
      w="full"
      bg="white"
      p="8"
      h="350px"
      boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
      as="article"
      width={{ base: "300px", md: "300px", lg: "400px" }}
    >
      <Box height="100px" width="84px">
        <Image w="full" objectFit="contain" h="full" alt={title} src={image} />
      </Box>
      <Heading
        color="primary.800"
        fontWeight="600"
        textAlign="center"
        fontSize={{ base: "", lg: "2rem" }}
      >
        {title}
      </Heading>
      <Text
        color="#83b2c1"
        fontWeight="semibold"
        noOfLines={5}
        textAlign="center"
        fontSize="1rem"
      >
        {description}
      </Text>
    </VStack>
  );
};

export default ServiceCard;
