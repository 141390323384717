import React, { useReducer, useCallback, useEffect, useState } from "react";
import cartReducer, { initialStateValues } from "../reducers/cart_reducer";

const CartContext = React.createContext();

export const CartContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialStateValues);
  const [cartQuantity, setCartQuantity] = useState("0");
  const [firstLoad, setFirstLoad] = useState(true);

  const addCart = useCallback((productType, product, quantity, category) => {
    return new Promise((resolve, reject) => {
      if (productType && product && quantity && category) {
        dispatch({
          type: "add",
          payload: {
            productType,
            product,
            quantity,
            category,
          },
        });

        resolve({ response: `${quantity} ${product.name} added successfully` });
      } else {
        reject({ response: "Unable to add item to cart" });
      }
    });
  }, []);

  const setCart = useCallback((productType, product, quantity, category) => {
    return new Promise((resolve, reject) => {
      if (productType && product && quantity && category) {
        dispatch({
          type: "set",
          payload: {
            productType,
            product,
            quantity,
            category,
          },
        });

        resolve({ response: `${quantity} ${product.name} added successfully` });
      } else {
        reject({ response: "Unable to add item to cart" });
      }
    });
  }, []);

  const clearCart = useCallback(() => {
    return new Promise((resolve, reject) => {
      dispatch({ type: "clear" });

      resolve({ response: "Cart cleared successfully" });
    });
  }, []);

  const removeCart = useCallback(
    (productType, product, quantity) => {
      return new Promise((resolve, reject) => {
        if (productType && product) {
          if (productType === "tickets" || productType === "ecommerce") {
            if (productType === "tickets") {
              var details = state?.tickets?.filter(
                (tick) => tick.id === product.id
              );

              if (details?.length < 1) {
                return;
              }
            } else {
              var edetails = state?.ecommerce?.filter(
                (tick) => tick.id === product.id
              );

              if (edetails?.length < 1) {
                return;
              }
            }
          } else {
            return;
          }

          quantity
            ? dispatch({
                type: "remove",
                payload: {
                  productType,
                  product,
                  quantity,
                },
              })
            : dispatch({
                type: "remove",
                payload: {
                  productType,
                  product,
                },
              });

          resolve({ response: "Item removed successfully" });
        } else {
          reject({ response: "Unable to remove item to cart" });
        }
      });
    },
    [state]
  );

  useEffect(() => {
    if (localStorage.getItem("cart")) {
      var cartDetails = JSON.parse(localStorage.getItem("cart"));
      dispatch({ type: "localStorage", payload: { data: cartDetails } });
    }
  }, []);

  useEffect(() => {
    var totalQuantity = "0";
    var totalEcommerceQuantity = 0;
    var totalTicketQuantity = 0;

    state?.ecommerce?.forEach((ecommerce) => {
      totalEcommerceQuantity += parseInt(ecommerce?.quantity);
    });

    state?.tickets?.forEach((tickets) => {
      totalTicketQuantity += parseInt(tickets?.quantity);
    });

    totalQuantity = (totalEcommerceQuantity + totalTicketQuantity).toString();

    if (!firstLoad) {
      setCartQuantity(totalQuantity);

      var jsonData = JSON.stringify(state);

      localStorage.setItem("cart", jsonData);
    }
  }, [state, firstLoad]);

  useEffect(() => {
    setFirstLoad(false);
  }, []);

  return (
    <CartContext.Provider
      value={{ state, addCart, clearCart, removeCart, setCart, cartQuantity }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
