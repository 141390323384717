import {
  Grid,
  Heading,
  Divider,
  Text,
  VStack,
  Button,
  useToast,
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  InputRightElement,
  Flex,
} from "@chakra-ui/react";
import { useContext } from "react";
import { BiMinus } from "react-icons/bi";
import { BsPlus } from "react-icons/bs";
import { FaTrashAlt } from "react-icons/fa";
import CartContext from "../../../context/cart_context";
import { getTodaysDate } from "../../../utils/helpers";

const CartSummary = ({ category, title, data, productDiscounts }) => {
  var { removeCart, addCart, setCart } = useContext(CartContext);

  const toast = useToast();

  return (
    <>
      <VStack alignItems="flex-start" w="full">
        <Heading
          color="#38C0F6"
          fontSize="1.1rem"
          fontFamily="DINPro"
          fontWeight="bold"
          mb={2}
        >
          {title?.toUpperCase()}
        </Heading>
        <VStack w="full" spacing="0" as="ul">
          {data.map((item, index) => {
            var originalItemPrice =
              parseInt(item.cost) * parseInt(item.quantity);
            var discountedItems = productDiscounts?.filter(
              (prod) => prod.id === item.id
            )[0]?.productDiscountResponses;
            var discountedPrice = 0;

            if (discountedItems) {
              var filteredPrice = discountedItems?.filter(
                (dis) =>
                  parseInt(dis.startRange) <= parseInt(item.quantity) &&
                  parseInt(dis.endRange) >= parseInt(item.quantity)
              );

              if (filteredPrice?.length > 0) {
                discountedPrice =
                  originalItemPrice *
                  (parseInt(filteredPrice[0]?.discountPercentage) / 100);
              }
            }

            var itemCost = originalItemPrice - discountedPrice;

            return (
              <Grid
                key={index}
                w="full"
                p=".3rem"
                gridTemplateColumns="2fr 1.5fr 0.5fr 1.5fr 0.5fr"
                opacity="0.8"
              >
                <Text fontWeight="600" textAlign="left">
                  {item.name}
                </Text>

                <Text fontWeight="600" textAlign="center">
                  {item.date && getTodaysDate(item.date)}
                </Text>

                <InputGroup
                  borderRadius="10px"
                  bg="white"
                  color="black"
                  w="fit-content" // Set the width to fit its content
                >
                  <InputLeftElement
                    onClick={() => {
                      removeCart(category, item, "1").then((res) => {});
                    }}
                    role="button"
                    children={<Icon color="#70D3F1" as={BiMinus} />}
                    zIndex={2}
                  />
                  <Input
                    w="7rem"
                    value={item.quantity}
                    textAlign="center"
                    type="number"
                    placeholder="01"
                    userSelect="none"
                    onChange={(e) => {
                      var quantiValue = e.target.value.toString();

                      if (item.quantity === 0) {
                        quantiValue = e.target.value.toString().slice(1);
                      }

                      if (quantiValue === "") {
                        quantiValue = "0";
                      }

                      if (parseInt(quantiValue) === 0) {
                        removeCart(category, item);
                      } else {
                        setCart(category, item, quantiValue, title);
                      }
                    }}
                  />
                  <InputRightElement
                    role="button"
                    onClick={() => {
                      addCart(category, item, "1", title).then((res) => {});
                    }}
                    children={<Icon color="#70D3F1" as={BsPlus} />}
                    zIndex={2}
                  />
                </InputGroup>

                <Flex flexDirection="column">
                  {itemCost.toString() !== originalItemPrice.toString() && (
                    <Text as="s" textAlign="center">
                      &#8358;{originalItemPrice.toLocaleString()}
                    </Text>
                  )}
                  <Text fontWeight="600" textAlign="center">
                    &#8358;{itemCost.toLocaleString()}
                  </Text>
                </Flex>

                <Button
                  colorScheme="red"
                  px={0}
                  py={2}
                  onClick={() => {
                    removeCart(category, item).then((res) => {
                      toast({
                        position: "top-right",
                        description: res.response,
                        status: "success",
                        duration: 2000,
                      });
                    });
                  }}
                >
                  <FaTrashAlt />
                </Button>
              </Grid>
            );
          })}
        </VStack>
      </VStack>
      <Divider
        border="2px solid"
        bg="#6c6274"
        borderColor="#6c6274"
        my="1rem"
      />
    </>
  );
};

export default CartSummary;
