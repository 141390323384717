import {
  Box,
  Collapse,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

//hooks
import { memo, useContext } from "react";
import { useNavigate } from "react-router-dom";

//logo imports
import Logo from "../../assets/logo.png";

//icons
import { AiOutlineClose, AiOutlineShoppingCart } from "react-icons/ai";
import { FaBars } from "react-icons/fa";
import useWindowSize from "../../utils/useWindowDimension";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import { RouteUrl } from "../../utils/routeConstatnt";
import CartContext from "../../context/cart_context";

const Navbar = memo(() => {
  const navigate = useNavigate();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { currentWidth } = useWindowSize();

  const { cartQuantity } = useContext(CartContext);
  return (
    <>
      <Flex
        px="2rem"
        position={{ base: "fixed", sm: "absolute" }}
        top={{ base: 0, sm: "40px" }}
        left={0}
        bg="primary.800"
        as="nav"
        w="full"
        h={{ base: "auto", sm: "52px" }}
        zIndex={99}
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          maxW="1400px"
          direction={{ base: "column", lg: "row" }}
          h="full"
          w="full"
          alignItems="center"
          color="white"
        >
          {currentWidth < 900 && (
            <Flex
              alignItems="center"
              width="full"
              justifyContent="space-between"
            >
              <Box
                w={{ base: "100px", lg: "150px" }}
                h={{ base: "70px", lg: "150px" }}
              >
                <Image
                  onClick={() => navigate("/")}
                  w="full"
                  h="full"
                  fit="contain"
                  src={Logo}
                  alt="keep logo"
                />
              </Box>

              <HStack>
                <Box
                  onClick={() => navigate(RouteUrl.CHECKOUT)}
                  cursor="pointer"
                  position="relative"
                  mr={5}
                >
                  <Icon fontSize="3.2rem" as={AiOutlineShoppingCart} />
                  <Flex
                    borderRadius="full"
                    w="35px"
                    h="35px"
                    alignItems="center"
                    justifyContent="center"
                    bg="white"
                    top="0"
                    right="-10px"
                    p="2px"
                    position="absolute"
                  >
                    <Text fontSize="14px" fontWeight="bold" color="primary.800">
                      {cartQuantity}
                    </Text>
                  </Flex>
                </Box>
                <IconButton
                  fontSize="1.5rem"
                  bg="green.400"
                  color="black"
                  _active={{
                    bg: "green.400",
                  }}
                  icon={isOpen ? <AiOutlineClose /> : <FaBars />}
                  aria-label="Mobile Toggler Button"
                  variant="ghost"
                  onClick={onToggle}
                />
              </HStack>
            </Flex>
          )}
          {currentWidth > 900 && <DesktopNav />}
          {currentWidth < 900 && (
            <div style={{ width: "100%" }}>
              <Collapse in={isOpen} animateOpacity>
                <MobileNav onClose={onClose} />
              </Collapse>
            </div>
          )}
        </Flex>
      </Flex>
    </>
  );
});

export default Navbar;
