import {
  Flex,
  Heading,
  Icon,
  Link,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { memo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Autoplay, Pagination } from "swiper";

import LocationComponent from "./LocationComponent";
import { ImAirplane } from "react-icons/im";
import { MdLocationOn } from "react-icons/md";
import { AiFillCar } from "react-icons/ai";
import sectionBaloon from "../../../../assets/section-water.svg";
const Location = memo(() => {
  return (
    <Flex
      py="100px"
      id="location"
      overflow="hidden"
      bg="#F1F8FF"
      h="full"
      w="full"
      as="section"
      position="relative"
    >
      <div className="wave">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
      <VStack
        px="2rem"
        spacing="20"
        w="full"
        h="full"
        maxW="1200px"
        marginInline="auto"
      >
        <Heading
          position="relative"
          fontWeight="600"
          fontSize={{ base: "3rem", sm: "5rem" }}
          color="primary.800"
          textAlign="center"
          marginTop={{ base: "1rem", sm: "3rem" }}
          _before={{
            content: `''`,
            position: "absolute",
            top: { base: "-120%", sm: "-70%" },
            left: "-8%",
            w: { base: "30px", sm: "50px" },
            h: "400px",
            transform: "scale(.5) rotate(0deg)",
            backgroundImage: sectionBaloon,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            display: { base: "none", sm: "block" },
          }}
          _after={{
            content: `''`,
            position: "absolute",
            top: { base: "-150%", sm: "-110%" },
            left: "100%",
            w: { base: "30px", sm: "50px" },
            h: "400px",
            backgroundImage: sectionBaloon,
            transform: "rotateY(180deg) scale(.5)",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            display: { base: "none", sm: "block" },
          }}
        >
          HOW TO GET HERE
        </Heading>
        <Flex
          // py="60px"
          overflow="hidden"
          h="full"
          w="full"
          // as="section"
          position="relative"
          // px="2rem"
          direction="column"
          alignItems="center"
          justifyContent="center"
          userSelect="none"
          marginTop={"0 !important"}
        >
          <Swiper
            style={{ height: "500px" }}
            freeMode={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
            }}
            modules={[FreeMode, Autoplay, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide className="location-swipe">
              <LocationComponent icon={ImAirplane} title="AIR ROUTES">
                <VStack spacing="1">
                  <Link
                    fontWeight="600"
                    color="#83B2C1"
                    fontSize="1.2rem"
                    href="https://www.google.ng/maps/dir/Asaba+International+Airport,+Asaba/Park+Vega+Waterpark,+Ewuru+Road,+Agbor/@6.2621643,6.2661159,11z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x1043f203a0dacb49:0xa9d120bdeda07fcd!2m2!1d6.6558829!2d6.212937!1m5!1m1!1s0x10414fa9377304d9:0x922b810dad8b347e!2m2!1d6.1538889!2d6.2483333"
                    target="_blank"
                  >
                    Asaba Airport
                  </Link>
                  <Link
                    fontWeight="600"
                    color="#83B2C1"
                    fontSize="1.2rem"
                    href="https://www.google.ng/maps/dir/Benin+Airport,+Airport+Road,+Benin+City/Park+Vega+Waterpark,+Ewuru+Road,+Agbor/@6.2975235,5.7504337,11z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x1040d249831048d1:0xa273e40648378c1c!2m2!1d5.6036533!2d6.3171797!1m5!1m1!1s0x10414fa9377304d9:0x922b810dad8b347e!2m2!1d6.1538889!2d6.2483333"
                    target="_blank"
                  >
                    Benin Airport
                  </Link>
                  <Link
                    fontWeight="600"
                    color="#83B2C1"
                    fontSize="1.2rem"
                    href="https://www.google.ng/maps/dir/Warri+Airport+(QRW),+Warri/Park+Vega+Waterpark,+Ewuru+Road,+Agbor/@5.9116923,5.6771042,10z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x1041aea1f53ae605:0x9db57abd7c3a05f5!2m2!1d5.819282!2d5.594487!1m5!1m1!1s0x10414fa9377304d9:0x922b810dad8b347e!2m2!1d6.1538889!2d6.2483333"
                    target="_blank"
                  >
                    Warri Airport
                  </Link>
                </VStack>
              </LocationComponent>
            </SwiperSlide>

            <SwiperSlide>
              <LocationComponent
                maxW="500px"
                bg="white"
                p="3rem 2rem"
                icon={MdLocationOn}
                shadow="xl"
                title="LOCATION"
              >
                <Text
                  textAlign="center"
                  fontSize="1.2rem"
                  fontWeight="600"
                  color="#83B2C1"
                >
                  ParkVega Street, Ewuru Road, Off Km 68 Benin-Asaba Expressway,
                  Agbor, Delta State.
                </Text>
              </LocationComponent>
            </SwiperSlide>

            <SwiperSlide className="location-swipe">
              <VStack
                mt={{ base: "8", lg: 0 }}
                justifyContent="center"
                spacing="6"
              >
                <Icon color="#0E63A1" fontSize="3rem" as={AiFillCar} />
                <Heading
                  textAlign="center"
                  as="a"
                  target="_blank"
                  color="primary.800"
                  href="https://www.google.ng/maps/place/Park+Vega+Waterpark/@6.2483333,6.1517002,17z/data=!3m1!4b1!4m5!3m4!1s0x10414fa9377304d9:0x922b810dad8b347e!8m2!3d6.2483333!4d6.1538889"
                >
                  LAND ROUTES
                </Heading>
              </VStack>
            </SwiperSlide>
          </Swiper>
        </Flex>
      </VStack>
    </Flex>
  );
});

export default Location;
