import HeadingComponent from "../../../../components/Heading";
import { Service } from "./utils";
import ServiceCard from "./component/ServiceCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, FreeMode, Autoplay } from "swiper";
import { Flex } from "@chakra-ui/react";

const Services = () => {
  return (
    <>
      <Flex
        id="services"
        py="100px"
        overflow="hidden"
        h="full"
        w="full"
        as="section"
        bg="white"
        position="relative"
        px="2rem"
        direction="column"
        alignItems="center"
        justifyContent="center"
        userSelect="none"
      >
        <HeadingComponent id="services" mb="2rem">
          SERVICES
        </HeadingComponent>
        <Swiper
          style={{ height: "500px" }}
          freeMode={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          }}
          modules={[FreeMode, Autoplay, Pagination]}
          className="mySwiper"
        >
          {Service.map((item) => (
            <SwiperSlide key={item.id}>
              <ServiceCard key={item.id} {...item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Flex>
    </>
  );
};

export default Services;
