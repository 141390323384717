import { Container, Flex, FlexProps } from "@chakra-ui/react";
import React from "react";

const Wrapper = ({ children, id, maxWi, cartCon, ...rest }) => {
  return (
    <Flex
      // py="50px"
      overflow="hidden"
      h="full"
      w="full"
      as="section"
      bg="white"
      position="relative"
      id={id}
      {...rest}
      paddingLeft={{ md: "1rem", base: "0" }}
      paddingRight={{ md: "1rem", base: "0" }}
    >
      <Container
        px={{ base: `${cartCon ? "0" : "2rem"}`, sm: "2rem" }}
        w="full"
        centerContent
        maxW={maxWi ? maxWi : "1400px"}
      >
        {children}
      </Container>
    </Flex>
  );
};

export default Wrapper;
