import Navbar from "../../components/navbar/Navbar";
import EventHero from "./component/EventHero";
import EventSection from "./component/EventSection";
import Calendar from "./component/Calendar";

const Events = () => {
  return (
    <div>
      <Navbar />
      <EventHero />
      <EventSection />
      <Calendar />
    </div>
  );
};

export default Events;
