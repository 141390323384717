import lifeJacket from "../../../../../assets/images/jackety.png";
import GiftShop from "../../../../../assets/images/giftShop.png";
import Locker from "../../../../../assets/images/lockery.png";
import restaurant from "../../../../../assets/images/restaurantY.png";
import WaterPark from "../../../../../assets/images/waterparkAttractionsY.png";
import SunLoungers from "../../../../../assets/images/sunloungersY.png";
import Bar from "../../../../../assets/images/barY.png";
import Cabana from "../../../../../assets/images/cabanasY.png";
import VegaMoney from "../../../../../assets/images/VegaMoneyY.png";

export const Service = [
  {
    id: 0,
    title: "Life Jackets",
    image: lifeJacket,
    description:
      "Complimentary Life jackets and Inner tube. Available on a first-come, first-serve basis",
  },
  {
    id: 1,
    title: "Gift Shop",
    image: GiftShop,
    description:
      "Stop in for all your fun-in-the-park supplies, including swimwear, sandals, filp-flops, towels, toys, gifts and treats.",
  },
  {
    id: 2,
    title: "Locker Rentals",
    image: Locker,
    description:
      "Rest easy and better enjoy the waterpark during your visit. Lock up your valuables in one of our lockers!",
  },
  {
    id: 3,
    title: "Restaurant",
    image: restaurant,
    description:
      "Enjoy a culinary experience with our tasty meals. The park will satisfy the most demanding taste buds, including children.",
  },
  {
    id: 4,
    title: "Bar",
    image: Bar,
    description:
      "Our Swim-up bar is a perfect place to lounge over glasses of cocktails, mocktails or fresh juice.",
  },
  {
    id: 5,
    title: "Waterpark Attractions",
    image: WaterPark,
    description: "All day unlimited use of ALL waterpark attractions",
  },
  {
    id: 6,
    title: "SUN LOUNGERS USE",
    image: SunLoungers,
    description:
      "Complimentary Sun Loungers. Available on a first-come, first-serve basis.",
  },
  {
    id: 7,
    title: "CABANA RENTAL",
    image: Cabana,
    description:
      "Relax in your own private cabana during your visit to the park! Cabana reservations are available on a first-come, first-serve basis online, or at the park the day of your visit.",
  },
  {
    id: 8,
    title: "VEGA MONEY",
    image: VegaMoney,
    description:
      "Simplify your day with this ideal solution for total freedom! All you have to do is load money unto your account and make purchases at any point.",
  },
];
