import { Heading, Icon, VStack } from "@chakra-ui/react";
import { memo } from "react";
import { IconType } from "react-icons";

const LocationComponent = memo(({ icon, title, children, ...rest }) => {
  return (
    <VStack
      borderRadius="10px"
      justifyContent="center"
      w="full"
      spacing={6}
      {...rest}
    >
      <Icon
        color={title === "LOCATION" ? "#FBB41E" : "#0E63A1"}
        fontSize="3rem"
        as={icon}
      />
      <Heading color="primary.800">{title}</Heading>
      {children}
    </VStack>
  );
});

export default LocationComponent;
