import { useEffect, useState } from "react";
import { Box, Button, Flex, Heading, Image, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { SwiperSlide, Swiper } from "swiper/react";
import hero1 from "../../../assets/hero1.jpg";
import hero2 from "../../../assets/hero2.jpg";
import hero3 from "../../../assets/hero3.jpg";
import { Autoplay, Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import ballon from "../../../assets/images/drop-2.png";
import { FaTicketAlt } from "react-icons/fa";
import { RouteUrl } from "../../../utils/routeConstatnt";
import { useProductsContext } from "../../../context/products_context";

const data = [
  {
    image: hero1,
  },
  {
    image: hero2,
  },
  {
    image: hero3,
  },
];

const Ban = () => {
  const navigate = useNavigate();

  const [bannerPicture, setBannerPictures] = useState([]);

  const { picture_content: pictures } = useProductsContext();

  useEffect(() => {
    if (pictures) {
      setBannerPictures(
        pictures.value.filter(
          (picture) =>
            picture.pageName.toLowerCase() === "home" &&
            picture.tagType.toLowerCase() === "hero"
        )
      );
    }
  }, [pictures]);

  return (
    <Flex position="relative" h={{ base: "70vh", lg: "80vh" }} w="full">
      <VStack
        justifyContent="center"
        maxW={{ base: "100%", sm: "100%", md: "700px", lg: "800px" }}
        marginInline="auto"
        w="full"
        h="full"
        position="absolute"
        zIndex={9}
        top={"50%"}
        left={"50%"}
        transform={"translate(-50%, -50%)"}
      >
        <Heading
          textAlign="center"
          color="white"
          fontWeight="600"
          fontSize={{ base: "2rem", sm: "3rem", lg: "6rem" }}
          position="relative"
          _before={{
            content: `''`,
            position: "absolute",
            top: 0,
            left: { base: 0, sm: "5%", lg: "-5%" },
            w: "100px",
            h: "100px",
            backgroundImage: ballon,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            display: { base: "none", sm: "block" },
          }}
          _after={{
            content: `''`,
            position: "absolute",
            top: { base: "60%", sm: "60%", lg: "30%" },
            left: { base: 0, sm: "75%", lg: "92%" },
            w: "100px",
            h: "100px",
            backgroundImage: ballon,
            transform: "rotate(180deg)",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            display: { base: "none", sm: "block" },
          }}
        >
          Welcome to Park Vega Waterpark
        </Heading>
        <Button
          rightIcon={<FaTicketAlt />}
          p=".5rem 1rem"
          backgroundColor="#97e549"
          colorScheme="green"
          fontSize={{ base: "1rem", sm: "1.2rem" }}
          onClick={() => navigate(RouteUrl.TICKETS)}
        >
          Buy Ticket
        </Button>
      </VStack>
      <Swiper
        style={{ height: "100%" }}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
        pagination={{
          clickable: true,
        }}
      >
        {bannerPicture?.length > 0 &&
          bannerPicture.map((item, index) => (
            <SwiperSlide key={index}>
              <Box
                position="relative"
                w="full"
                h="full"
                _after={{
                  content: `''`,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  w: "full",
                  h: "full",
                  background: "rgba(0,0,0,0.5)",
                }}
              >
                <Image
                  src={item?.imageUrl}
                  alt={"Hero Images " + index}
                  w="full"
                  h="full"
                  fit="cover"
                />
              </Box>
            </SwiperSlide>
          ))}

        {bannerPicture?.length < 1 &&
          data.map((item, index) => (
            <SwiperSlide key={index}>
              <Box
                position="relative"
                w="full"
                h="full"
                _after={{
                  content: `''`,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  w: "full",
                  h: "full",
                  background: "rgba(0,0,0,0.5)",
                }}
              >
                <Image
                  src={item.image}
                  alt="Hero Images"
                  w="full"
                  h="full"
                  fit="cover"
                />
              </Box>
            </SwiperSlide>
          ))}
      </Swiper>
    </Flex>
  );
};

export default Ban;
