import { Heading, HeadingProps } from "@chakra-ui/react";
import sectionBaloon from "../assets/section-water.svg";
const HeadingComponent = ({ children, ...rest }) => {
  return (
    <Heading
      position="relative"
      fontWeight="600"
      fontSize={{ base: "3rem", sm: "5rem" }}
      color="primary.800"
      textAlign="center"
      marginTop={{ base: "1rem", sm: "3rem" }}
      _before={{
        content: `''`,
        position: "absolute",
        top: { base: "-120%", sm: "-73%" },
        left: "-15%",
        w: { base: "30px", sm: "50px" },
        h: "400px",
        transform: "scale(.5) rotate(0deg)",
        backgroundImage: sectionBaloon,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        display: { base: "none", sm: "block" },
      }}
      _after={{
        content: `''`,
        position: "absolute",
        top: { base: "-150%", sm: "-110%" },
        left: "95%",
        w: { base: "30px", sm: "50px" },
        h: "400px",
        backgroundImage: sectionBaloon,
        transform: "rotateY(180deg) scale(.5)",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        display: { base: "none", sm: "block" },
      }}
      {...rest}
    >
      {children}
    </Heading>
  );
};

export default HeadingComponent;
