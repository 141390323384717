//chakra ui
import {
  Box,
  Flex,
  UnorderedList,
} from "@chakra-ui/layout";
import {
  Button,
  Text,
  Heading,
} from "@chakra-ui/react";

//framer
import { motion } from "framer-motion";

/*
 * Example of how to use the motion library along with chakraUI.
 * This makes us use chakra styles with chakra component with framer motion.
 * Feel free to add  many HtmlTag as possible.
 */
export const MotionBox = motion(Box);
export const MotionFlex = motion(Flex);
export const MotionText = motion(Text);
export const MotionHead = motion(Heading);
export const MotionUl = motion(UnorderedList);
export const MotionButton = motion(Button);
