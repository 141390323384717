import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { chakraTheme } from "./chakra";
import { ProductsProvider } from "./context/products_context";
import { MessageProvider } from "./context/message_context.jsx";
import { CartContextProvider } from "./context/cart_context";
import "./index.css";
import "swiper/css/bundle";
import { UserContextProvider } from "./context/UserContext";

ReactDOM.render(
       <BrowserRouter>
        <UserContextProvider>
          <ChakraProvider theme={chakraTheme}>
            <MessageProvider>
              <ProductsProvider>
                <CartContextProvider>
                  <App />
                </CartContextProvider>
              </ProductsProvider>
            </MessageProvider>
          </ChakraProvider>
        </UserContextProvider>
      </BrowserRouter>,
  document.getElementById("root")
);
