import React, { useState, useEffect } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Stack,
  Button,
  Heading,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthData } from "../../utils/userRequest";

import CartNavbar from "../../components/navbar/CartNavbar";
import Wrapper from "../../components/Wrapper";
import Navbar from "../../components/navbar/Navbar";

function Login(props) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const handleSignin = async () => {
    setLoader(true);
    const payload = {
      email: email,
      password: password,
    };

    try {
      const item = AuthData("/Auth/login", payload);

      item
        .then((res) => {
          const emailID = localStorage.setItem(
            "userEmail",
            res?.data?.value?.email
          );
          const firstName = localStorage.setItem(
            "firstName",
            res?.data?.value?.firstName
          );
          const mobileNumber = localStorage.setItem(
            "mobileNumber",
            res?.data?.value?.mobileNumber
          );

          const lastName = localStorage.setItem(
            "lastName",
            res?.data?.value?.lastName
          );

          const userId = res?.data?.value?.userId;
          localStorage.setItem(
            "userId",
            userId
          );
          const user_token = res?.data?.value?.token;

          //get the user role
          const userRole = res?.data?.value?.role;

          if (userRole === "Customer") {
            //set jwt to localstorage
            if (typeof window !== "undefined") {
              localStorage.setItem("user_token", user_token);
              navigate("/checkout");
            } else {
              toast({
                title: "Logged In Sucessfully",
                position: "top-right",
                status: "success",
                duration: 4000,
                isClosable: true,
              });
            }
          } else {
            toast({
              title: "Unauthorized user",
              position: "top-right",
              status: "error",
              duration: 4000,
              isClosable: true,
            });
            // navigate("/login");
          }
        })
        .catch((err) => {
          //log error to toast
          const errMsg =
            err?.response?.data?.title || err?.response?.data?.message;
          toast({
            title: errMsg,
            position: "top-right",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          setLoader(false);
        });
    } catch (err) {
      setLoader(false);
    }
  };

  //check if token is actually present
  useEffect(() => {
    localStorage.getItem("user_token") ? navigate("/") : navigate("/login");
  }, []);

  return (
    <>
      <Navbar />
      <Wrapper maxWi="1200px" cartCon>
        <Flex minH={"100vh"} align={"center"} justify={"center"}>
          <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
            <Stack align={"center"}>
              <Heading fontSize={"2xl"}>Login Now To Make Payment</Heading>
              <Text fontSize={"lg"} color={"gray.600"}>
                Don't have an account ?{" "}
                <Link to="/register" color={"blue.400"}>
                  Register Now
                </Link>
              </Text>
            </Stack>

            <Box rounded={"lg"} bg={"white"} boxShadow={"lg"} p={8}>
              <Stack spacing={4}>
                <FormControl id="email">
                  <FormLabel>Email address</FormLabel>
                  <Input
                    type={"email"}
                    placeholder={"Email"}
                    label={"email"}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                <FormControl id="password">
                  <FormLabel>Password</FormLabel>
                  <Input
                    type={"password"}
                    placeholder={"Password"}
                    label={"Password"}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormControl>
                <Stack spacing={10}>
                  <Stack
                    direction={{ base: "column", sm: "row" }}
                    align={"start"}
                    justify={"space-between"}
                  >
                    {/* <Checkbox>Remember me</Checkbox> */}
                    <Link to="/forgot-password" color={"blue.400"}>
                      Forgot password?
                    </Link>
                  </Stack>
                  <Button
                    bg={"primary.800"}
                    color={"white"}
                    _hover={{
                      bg: "blue.500",
                    }}
                    onClick={handleSignin}
                    isLoading={loader}
                  >
                    Sign in
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Flex>
      </Wrapper>
    </>
  );
}

export default Login;
