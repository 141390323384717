import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Wrapper from "../../components/Wrapper";
import { Heading, Text, Box } from "@chakra-ui/react";

function Privacy(props) {
  return (
    <>
      <Navbar />
      <Wrapper>
        <Box marginTop="10rem" marginBottom="5rem">
          <Heading>PRIVACY POLICY</Heading>
          <Text marginTop="3rem">
            The following describes the Privacy Policy of Park Vega Waterpark,
            which includes our policy for information received from or about you
            during visits to the website. The paragraphs below disclose the type
            of information collected on the website and how it is used. This
            privacy statement may change at any time. Any changes will be posted
            on this page.
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                PERSONAL INFORMATION
              </Heading>
              Our website will only collect personal information which you
              knowingly and voluntarily provide, such as when you send us an
              e-mail, complete a membership form, make a reservation, or order
              tickets. We use this information to process and send ticket orders
              and contact the visitor when necessary. Personal information
              collected online is used only by us. It is not sold or provided to
              vendors, direct mail concerns, e-mail list services, etc.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                CHILDREN’S PRIVACY (INDIVIDUALS UNDER 12 YEARS OF AGE)
              </Heading>
              Park Vega Waterpark is committed to protecting the privacy of
              children and does not knowingly collect, use, or disclose personal
              identifiable information about children under 12 who visit our
              website without prior parental consent.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                USE OF IP ADDRESSES
              </Heading>
              We use IP addresses to help diagnose problems with our server, and
              to administer various features of our site. Your IP address is
              also used to gather broad demographic information such as the name
              of your Internet service provider and its geographic location.
              This information does not personally identify you, and your e-mail
              address is not collected as a result of this process. We use this
              information to evaluate and improve the content of our site and
              make it more useful to visitors.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                COOKIES
              </Heading>
              On the Internet, a cookie is piece information that a website
              transfers to a user’s computer for record-keeping purposes. As a
              user moves from page to page on the Park Vega Waterpark website, a
              cookie is the only way that we have of knowing who you are. An
              activity on one page may depend on what you have selected on a
              previous page. We use a cookie to keep track of your selection.
              Without cookies, users may be asked to provide the same
              information repeatedly or some activities would not function at
              all. <br /> <br />
              The use of cookies is common on the Internet. Most major websites
              use cookies. Park Vega Waterpark uses cookies only when
              appropriate. We never use cookies to retrieve information from
              your computer that was not originally sent in a cookie from us. We
              do not use information transferred through cookies for advertising
              purposes. We never share cookies information with any third
              parties for marketing or other purposes.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                REGISTRATION FORM
              </Heading>
              Our site’s registration form requires users to give us contact
              information (like their name and e-mail address, etc.). We use
              customer contact information from the registration form to send
              the user information about Park Vega Waterpark. The customer’s
              contact information is also used to contact the visitor when
              necessary. Users may opt-out of receiving future mailings.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                REQUEST FOR INFORMATION
              </Heading>
              Our site uses a form for customers to request information,
              tickets, and services. We collect visitor’s contact information
              (like their e-mail address) and financial information (like their
              account or credit card numbers). Contact information from the form
              is used to send tickets and information about Park Vega Waterpark
              to our customers. The customer’s contact information is also used
              to get in touch with the visitor when necessary. Financial
              information that is collected is used to bill the user for tickets
              and services.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                LINKS
              </Heading>
              This website contains links to other sites. When you link to
              another site, you are no longer on our site and are subject to
              privacy and other practices of the new site. We are not
              responsible for the practices or content of these or any other
              sites.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                CONTESTS
              </Heading>
              We may periodically run contests on our site in which we ask
              visitors for contact information (like their e-mail address). We
              use contact data from our contests to send users information about
              Park Vega Waterpark. The customer’s contact information is also
              used to contact the visitor when necessary.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                SECURITY
              </Heading>
              This site has security measures in place to protect the loss,
              misuse and alteration of the information under our control. When
              you place ticket orders our system makes use of secure server
              software. The secure server software (SSL) encrypts personal order
              information you input before it is sent to us. Furthermore, all of
              the customer data we collect is protected against unauthorized
              access.
            </Text>
          </Text>
        </Box>
      </Wrapper>
    </>
  );
}

export default Privacy;
