import {
  Box,
  Grid,
  HStack,
  Icon,
  Image,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

//hooks
import { memo } from "react";
import { useNavigate } from "react-router-dom";

//utils
import { IconType } from "react-icons/lib";
import { ParkListLabel, prohibited } from ".";
import rules from "../../../../assets/rules.jpg";
import { RouteUrl } from "../../../../utils/routeConstatnt";
//components
import ModalComponent from "./ModalComponent";

const ParkInformation = memo(({ icon, title, label }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClick = () => {
    if (ParkListLabel.TIKCET === label) navigate(RouteUrl.TICKETS);
    else onOpen();
  };

  return (
    <>
      <HStack
        onClick={handleClick}
        cursor="pointer"
        alignItems="center"
        as="li"
      >
        <Box
          mt="1"
          boxSize="10"
          shadow="lg"
          p=".5rem"
          borderRadius="full"
          bg="white"
        >
          <Icon fontSize="1.5rem" color="#fc8c24" as={icon} />
        </Box>
        <Text
          fontWeight="500"
          color="#006"
          fontSize={{ base: "1.7rem", lg: "1.7rem" }}
          fontFamily="Berlin sans"
        >
          {title}
        </Text>
      </HStack>
      <ModalComponent isOpen={isOpen} onClose={onClose}>
        {label !== ParkListLabel.PROHIBITED ? (
          <Image w="full" h="full" fit="contain" src={rules} alt="Park Rules" />
        ) : (
          <>
            <ModalHeader>PROHIBITED ITEMS</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Grid
                w="full"
                gridGap="1rem"
                sx={{
                  gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
                }}
              >
                {prohibited.map((item, index) => (
                  <VStack key={index}>
                    <Image w="full" h="full" src={item.img} alt={item.name} />
                    <Text>{item.name}</Text>
                  </VStack>
                ))}
              </Grid>
            </ModalBody>
          </>
        )}
      </ModalComponent>
    </>
  );
});

export default ParkInformation;
