import {
    GET_ECOMMERCE_BEGIN,
    GET_ECOMMERCE_SUCCESS,
    GET_ECOMMERCE_ERROR,
    GET_TICKET_BEGIN,
    GET_TICKET_SUCCESS,
    GET_TICKET_ERROR,
    GET_PICTURE_SUCCESS,
    GET_PICTURE_ERROR
  } from '../action'
  
  const products_reducer = (state, action) => {
    if (action.type === GET_PICTURE_SUCCESS) {
      return { ...state, picture_loading: false, picture_content: action.payload }
    }

    if (action.type === GET_PICTURE_ERROR) {
      return { ...state, picture_loading: false, picture_error: true }
    }
    if( action.type === GET_ECOMMERCE_BEGIN ) {
      return {...state, ecommerce_loading: true}
    }
    if(action.type === GET_ECOMMERCE_SUCCESS) {
      return {
        ...state,
        ecommerce_loading: false,
        ecommerce_products: action.payload,
      }
    }
    if(action.type === GET_ECOMMERCE_ERROR) {
      return { ...state, ecommerce_loading: false, ecommerce_error: true }
    }

    if( action.type === GET_TICKET_BEGIN ) {
      return {...state, ticket_loading: true}
    }
    if(action.type === GET_TICKET_SUCCESS) {
      return {
        ...state,
        ticket_loading: false,
        ticket_products: action.payload,
      }
    }
    if(action.type === GET_TICKET_ERROR) {
      return { ...state, ticket_loading: false, ticket_error: true }
    }
    
    throw new Error(`No Matching "${action.type}" - action type`)
  }
  
  export default products_reducer
  