export const GET_ECOMMERCE_BEGIN = 'GET_ECOMMERCE_BEGIN'
export const GET_ECOMMERCE_SUCCESS = 'GET_ECOMMERCE_SUCCESS'
export const GET_ECOMMERCE_ERROR = 'GET_ECOMMERCE_ERROR'
export const GET_TICKET_BEGIN = 'GET_TICKET_BEGIN'
export const GET_TICKET_SUCCESS = 'GET_TICKET_SUCCESS'
export const GET_TICKET_ERROR = 'GET_TICKET_ERROR'
export const GET_PICTURE_ERROR = 'GET_PICTURE_ERROR'
export const GET_PICTURE_SUCCESS = 'GET_PICTURE_SUCCESS'
// export const ADD_TO_CART = 'ADD_TO_CART'
// export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM'
// export const TOGGLE_CART_ITEM_AMOUNT = 'TOGGLE_CART_ITEM_AMOUNT'
// export const CLEAR_CART = 'CLEAR_CART'
// export const COUNT_CART_TOTALS = 'COUNT_CART_TOTALS'
