import axios from "axios";
import { toast } from "react-toastify"
import { baseUrl } from "./helpers";

export const  PostRequest = async ( url , payloads ) => {
    
    try {
        return  await axios.post(  `${baseUrl}${url}` , payloads, {
            'Content-Type':'application/json',

            headers: {
                'Authorization':  `Bearer ${localStorage.getItem('user_token')}`
            }
        } )
        .then( res => {
            const msg = res?.data?.message; 
            toast.success(msg , {
                theme:'colored',
                hideProgressBar: true
            });
            
        }).catch(err => {
            //log error to toast 
            const errMsg = err?.response?.data?.message
            toast.error(errMsg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                });
        })
    } catch ( err  ) {

        return err;

    }
    
}

//auth login data 
export const  AuthData = async ( url , payloads ) => {
    
    return await axios.post(`${baseUrl}${url}` , payloads, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('user_token')}`
        }
    } )

}
