import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Image,
} from "@chakra-ui/react";
import { memo } from "react";

const ModalComponent = memo(({ isOpen, onClose, children }) => {
  return (
    <Modal
      motionPreset="scale"
      isOpen={isOpen}
      onClose={onClose}
      variant="wide"
    >
      <ModalOverlay />
      <ModalContent>{children}</ModalContent>
    </Modal>
  );
});

export default ModalComponent;
