import { Image, SimpleGrid, Text, Box, Heading } from "@chakra-ui/react";
import kidsPool from "../../../assets/images/kids-pool.png";
import Wrapper from "../../../components/Wrapper";
import sectionBaloon from "../../../assets/section-water.svg";
import { useEffect, useState } from "react";
import { useProductsContext } from "../../../context/products_context";

const EventSection = () => {
  const { picture_content: pictures } = useProductsContext();

  const [eventGallery, setEventGallery] = useState(null);

  useEffect(() => {
    var gallery = null;

    if (pictures) {
      gallery = pictures?.value.filter(
        (picture) =>
          picture.pageName.toLowerCase() === "event" &&
          picture.tagType.toLowerCase() === "gallery"
      )[0];
    }

    setEventGallery(gallery);
  }, [pictures]);
  return (
    <Wrapper id="fun">
      <Heading
        position="relative"
        fontWeight="600"
        fontSize={{ base: "3rem", sm: "5rem" }}
        color="primary.800"
        textAlign="center"
        marginTop={{ base: "1rem", sm: "3rem" }}
        _before={{
          content: `''`,
          position: "absolute",
          top: { base: "-120%", sm: "-73%" },
          left: "-5%",
          w: { base: "30px", sm: "50px" },
          h: "400px",
          transform: "scale(.5) rotate(0deg)",
          backgroundImage: sectionBaloon,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          // d: { base: "none", sm: "block" },
        }}
        _after={{
          content: `''`,
          position: "absolute",
          top: { base: "-150%", sm: "-110%" },
          // left: "95%",
          w: { base: "30px", sm: "50px" },
          h: "400px",
          backgroundImage: sectionBaloon,
          transform: "rotateY(180deg) scale(.5)",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          // d: { base: "none", sm: "flex" },
        }}
      >
        THE FUN NEVER ENDS!
      </Heading>

      <SimpleGrid
        mt="8"
        placeItems="center"
        gridGap="2rem"
        columns={[1, 1, 2, 2]}
        w="full"
      >
        <Box borderRadius="10px" shadow="lg" p="1rem" h="full" w="full">
          <Image
            src={eventGallery?.imageUrl ? eventGallery.imageUrl : kidsPool}
            alt="KidsPool Image"
            w="full"
            h="full"
            fit="contain"
          />
        </Box>
        <Box mt="1" p=".5rem" bg="white">
          <Text
            fontWeight="500"
            color="#83b2c1"
            fontSize="1.9rem"
            fontFamily="DINPro,sans-serif"
          >
            {eventGallery?.description
              ? eventGallery?.description
              : "It’s your kind of fun! A host of activities such as games, live music, tasty treats…keeps the fun and excitement going for you!"}
          </Text>
        </Box>
      </SimpleGrid>
    </Wrapper>
  );
};

export default EventSection;
