import { chakra, Grid, Text, VStack } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { getFullDayName, getUnactiveDays } from "../../../utils/helpers";

const StyledDatePicker = chakra(DatePicker);

const OneDay = ({ setDates, ticket, days }) => {
  const [startDate, setStartDate] = useState(new Date());

  const isWeekday = useCallback(
    (date) => {
      var fullDays = days?.map((dy) => getFullDayName(dy));
      var dayNum = getUnactiveDays(fullDays);

      var day = new Date(date).getDay();
      return !dayNum.includes(day.toString());
    },
    [days]
  );

  useEffect(() => {
    var dateValue = {
      id: ticket.id,
      date: startDate,
    };

    setDates((prevState) => {
      var dateFiltered = prevState?.filter((data) => data.id !== dateValue.id);

      return [...dateFiltered, dateValue];
    });
  }, [startDate, setDates, ticket]);

  return (
    <>
      <VStack
        direction="column"
        px={{ base: "1rem", sm: "2rem" }}
        py="5px"
        color="white"
        mb="5px"
      >
        <Grid
          placeItems="center"
          gridColumnGap={{ base: ".5rem", sm: "1rem" }}
          w="full"
          gridTemplateColumns={{
            base: "1.2fr 1fr 1fr",
            sm: "1.5fr 1fr 1fr 1fr",
          }}
          pt={3}
        >
          <Text></Text>
          <Text>Quantity</Text>
          <Text>Price</Text>
        </Grid>
      </VStack>
    </>
  );
};

export default OneDay;
