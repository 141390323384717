import React, { useState, useEffect } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
// import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { AuthData, PostRequest } from "../../utils/userRequest";
import CartNavbar from "../../components/navbar/CartNavbar";
import Wrapper from "../../components/Wrapper";
import Navbar from "../../components/navbar/Navbar";

function ForgotPassword(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [loader, setLoader] = useState(false);
  const toast = useToast();

  const forgotPassword = async () => {
    setLoader(true);

    const payloads = {
      email: email,
    };

    try {
      const item = AuthData("/Auth/RegisterUser", payloads);

      item
        .then((res) => {
          toast({
            title: res?.data?.message,
            position: "top-right",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          navigate("/login");
        })
        .catch((err) => {
          //log error to toast
          const errMsg =
            err?.response?.data?.title || err?.response?.data?.message;
          toast({
            title: errMsg,
            position: "top-right",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
      setTimeout(() => setLoader(false), 1500);
    } catch (err) {
      setTimeout(() => setLoader(false), 1500);
    }
  };

  return (
    <>
      <Navbar />
      <Wrapper cartCon maxWi="1200px">
        <Flex minH={"30vh"} mt={"8rem"} align={"center"} justify={"center"}>
          <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
            <Stack align={"center"}>
              <Text fontSize={"lg"} color={"gray.600"}>
                Enter Your email address below
              </Text>
            </Stack>
            <Box
              rounded={"lg"}
              bg={useColorModeValue("white", "gray.700")}
              boxShadow={"lg"}
              p={8}
            >
              <Stack spacing={4}>
                <FormControl id="email">
                  {/* <FormLabel>Email address</FormLabel> */}
                  <Input
                    type={"email"}
                    placeholder={"Email"}
                    label={"email"}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>

                <Stack spacing={10}>
                  <Button
                    bg={"primary.800"}
                    color={"white"}
                    _hover={{
                      bg: "blue.500",
                    }}
                    onClick={forgotPassword}
                    isLoading={loader}
                  >
                    Send
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Flex>
      </Wrapper>
    </>
  );
}

export default ForgotPassword;
