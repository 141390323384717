import { RouteUrl } from "../../../utils/routeConstatnt";
import { BiMap, BiPhone, BiMailSend } from "react-icons/bi";

export const quickLinks = [
  {
    label: "PARK RULES",
    url: RouteUrl.PLAN_VISIT,
  },
  {
    label: "TERMS & CONDITIONS",
    url: RouteUrl.TERMS,
  },
  {
    label: "OPENING HOURS",
    url: RouteUrl.PLAN_VISIT,
  },
  {
    label: "LOCATION",
    url: RouteUrl.LOCATIONS,
  },
  {
    label: "PRIVACY POLICY",
    url: RouteUrl.PRIVACY,
  },
];

export const contacts = [
  {
    icon: BiMap,
    text: "ParkVega Street, Ewuru Road, Off Km 68 Benin-Asaba Expressway, Agbor, Delta State",
  },
  {
    icon: BiPhone,
    text: "0802 444 0445",
  },
  {
    icon: BiMailSend,
    text: "info@parkvega.com",
  },
];
