import React, { useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";

//components
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./components/footer/Footer";

//utils
import { Routes, Route } from "react-router-dom";
import { RouteUrl } from "./utils/routeConstatnt";

//pages
import Home from "./pages/home/Home";
import Ticket from "./pages/tickets/Ticket";
// import Restaurant from "./pages/restaurant/Restaurant";
import Events from "./pages/events/Events";
// import Hotel from "./pages/hotel/Hotel";
import Food from "./pages/food/Food";
import Checkout from "./pages/checkout/Checkout";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import ForgotPassword from "./pages/forgot-password/ForgotPassword";
import Terms from "./pages/terms/Terms";
import Privacy from "./pages/privacy/Privacy";

import useIdle from "./utils/useIdleTimer";

function App() {
  const user = localStorage.getItem("user_token");

  const logout = () => {
    if (!user) {
      return;
    } else {
      localStorage.clear();
      window.location.pathname = "/";
    }
  };
  const { isIdle } = useIdle({ onIdle: logout, idleTime: 15 });

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path={RouteUrl.HOME} element={<Home />} />
        {/* <Route path={RouteUrl.RESTAURANT} element={<Restaurant />} /> */}
        <Route path={RouteUrl.EVENTS} element={<Events />} />
        {/* <Route path={RouteUrl.FOOD} element={<Food />} /> */}
        <Route path={RouteUrl.CHECKOUT} element={<Checkout />} />
        <Route path={RouteUrl.TICKETS} element={<Ticket />} />
        <Route path={RouteUrl.LOGIN} element={<Login />} />
        <Route path={RouteUrl.REGISTER} element={<Register />} />
        <Route path={RouteUrl.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={RouteUrl.TERMS} element={<Terms />} />
        <Route path={RouteUrl.PRIVACY} element={<Privacy />} />

        {/* <Route path={RouteUrl.HOTELS} element={<Hotel />} /> */}
      </Routes>
      <Footer />
    </>
  );
}

export default App;
