import { extendTheme } from "@chakra-ui/react";
import { colors } from "./colors";

const breakpoints = {
  xs: "480px",
  sm: "600px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1430px",
};

export const chakraTheme = extendTheme({
  fonts: {
    heading: `Berlin Sans, sans-serif`,
    body: `DINPro, sans-serif`,
  },
  colors,
  breakpoints,
  components: {
    Button: {
      baseStyle: {
        "&:focus": {
          boxShadow: "none",
        },
      },
    },

    Link: {
      baseStyle: {
        "&:hover": {
          textDecoration: "none",
        },
        "&:focus": {
          boxShadow: "none",
        },
      },
    },
    Modal: {
      parts: ["content"],
      variants: {
        wide: {
          dialog: {
            maxWidth: ["90%", "80%", "80%", "80%"],
          },
        },
      },
    },
  },
});
