import {
  Container,
  Text,
  Flex,
  SimpleGrid,
  VStack,
  Image,
  Box,
  UnorderedList,
  ListItem,
  Link as ChakraLink,
  chakra,
  Icon,
  Heading,
  HStack,
  Center,
  useDisclosure,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

// Hooks
import { memo } from "react";
import { useNavigate } from "react-router-dom";

// Logo imports
import Logo from "../../assets/logo.png";

// Contents
import { quickLinks, contacts } from "./utils";
import { RouteUrl } from "../../utils/routeConstatnt";
import { socials } from "../../utils/socials";
import ModalComponent from "../../pages/home/components/planVisit/ModalComponent";

const Footer = memo(() => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClick = () => {
    onOpen();
  };

  return (
    <VStack as="footer" w="full" spacing={0}>
      <Flex py="100px" px="2rem" bg="primary.800" w="full">
        <Container color="white" centerContent w="full" maxW="1024px">
          <SimpleGrid
            w="full"
            gridColumnGap="5rem"
            gridRowGap="2rem"
            columns={[1, 2, 2, 3]}
          >
            <Flex as="article" h="full" w="full" direction="column">
              <Box h="150px">
                <Image
                  onClick={() => navigate(RouteUrl.HOME)}
                  w="full"
                  h="full"
                  fit="contain"
                  src={Logo}
                />
              </Box>
              <UnorderedList
                h="full"
                display="flex"
                w="full"
                justifyContent="space-between"
              >
                {socials.map((item, index) => (
                  <ListItem listStyleType="none" key={index}>
                    <ChakraLink
                      _hover={{
                        opacity: "0.8",
                        transform: "scale(1.1)",
                      }}
                      href={item.url}
                    >
                      <Box w="50px" h="50px">
                        <Image
                          w="full"
                          h="full"
                          fit="contain"
                          src={item.icon}
                          alt="Socials Image"
                        />
                      </Box>
                    </ChakraLink>
                  </ListItem>
                ))}
              </UnorderedList>
            </Flex>
            <Flex as="article" w="full" direction="column">
              <Heading fontWeight="400" fontSize="2rem" mb="4">
                QUICK LINKS
              </Heading>
              <UnorderedList pl={{ base: "0", sm: "4" }}>
                {quickLinks.map((item, index) => (
                  <ListItem
                    sx={{
                      "&::marker": {
                        color: "#D3C354",
                      },
                    }}
                    key={index}
                  >
                    {item.url.startsWith("/#") ? (
                      <ChakraLink
                        _hover={{
                          opacity: "0.8",
                          transform: "scale(1.1)",
                        }}
                        href={item.url}
                      >
                        {item.label}
                      </ChakraLink>
                    ) : (
                      <ChakraLink
                        _hover={{
                          opacity: "0.8",
                          transform: "scale(1.1)",
                        }}
                        as={RouterLink}
                        to={item.url}
                      >
                        {item.label}
                      </ChakraLink>
                    )}
                  </ListItem>
                ))}
              </UnorderedList>
            </Flex>
            <Flex w="full" direction="column" as="article">
              <Heading fontWeight="400" fontSize="2rem" mb="4">
                CONTACT
              </Heading>
              <UnorderedList m="0">
                {contacts.map((item, index) => (
                  <ListItem
                    _notLast={{ mb: "4" }}
                    listStyleType="none"
                    key={index}
                  >
                    <HStack spacing="4" w="full">
                      <Icon fontSize="1.5rem" color="#D3C354" as={item.icon} />
                      <Text>{item.text}</Text>
                    </HStack>
                  </ListItem>
                ))}
              </UnorderedList>
            </Flex>
          </SimpleGrid>
        </Container>
      </Flex>
      <Center py="20px" bg="primary.400" w="full">
        <Text color="aliceblue" textAlign="center">
          COPYRIGHT ©{" "}
          <chakra.span fontSize="1.1rem">
            {new Date().getFullYear()}
          </chakra.span>{" "}
          PARK VEGA. ALL RIGHTS RESERVED.
        </Text>
      </Center>
    </VStack>
  );
});

export default Footer;
