import yt from "../assets/socials/yt.png";
import fb from "../assets/socials/fb.png";
import insta from "../assets/socials/insta.png";
import twitter from "../assets/socials/twitter.png";

export const socials = [
  {
    url: "https://www.youtube.com/channel/UCRfjBdvNK_wzJwqkDWaXdOA",
    icon: yt,
  },
  {
    icon: fb,
    url: "https://www.facebook.com/parkvegawaterpark",
  },
  {
    icon: insta,
    url: "https://www.instagram.com/parkvegawaterpark/",
  },

  {
    icon: twitter,
    url: "https://twitter.com/ParkVegaWP",
  },
];

export const url = 'https://parkvega-api.azurewebsites.net/api';
