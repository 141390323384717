import React, { useContext } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Link as ChakraLink,
  Text,
  Icon,
} from "@chakra-ui/react";
import { navbarLinks } from "./utils";
import { useNavigate, NavLink as RouteLink } from "react-router-dom";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { RouteUrl } from "../../utils/routeConstatnt";
import CartContext from "../../context/cart_context.js";
import Logo from "../../assets/logo.png";

const DesktopNav = () => {
  const navigate = useNavigate();
  const { cartQuantity } = useContext(CartContext);

  // const handleLinkClick = (url) => {
  //   if (url.startsWith("/#")) {
  //     const id = url.substring(2);
  //     const element = document.getElementById(id);
  //     if (element) {
  //       element.scrollIntoView({ behavior: "smooth" });
  //     }
  //   } else {
  //     navigate(url);
  //   }
  // };

  return (
    <Flex w="full" alignItems="center" justifyContent="center">
      <Flex as="ul" gap="3rem" alignItems="center">
        {navbarLinks.slice(0, 4).map((link, index) => {
          return (
            <Flex key={index} as="li">
              {!link.url.startsWith("/#") ? (
                <ChakraLink
                  _hover={{ textDecoration: "none", opacity: 0.8 }}
                  whiteSpace="nowrap"
                  as={
                    RouteUrl.FOOD === link.url ||
                    RouteUrl.RESTAURANT === link.url ||
                    RouteUrl.BLOG === link.url
                      ? "a"
                      : RouteLink
                  }
                  to={link.url}
                  href={
                    RouteUrl.FOOD === link.url ||
                    RouteUrl.RESTAURANT === link.url ||
                    RouteUrl.BLOG === link.url
                      ? link.url
                      : undefined
                  }
                  target={
                    RouteUrl.FOOD === link.url ||
                    RouteUrl.RESTAURANT === link.url ||
                    RouteUrl.BLOG === link.url
                      ? "_blank"
                      : undefined
                  }
                  rel={
                    RouteUrl.FOOD === link.url ||
                    RouteUrl.RESTAURANT === link.url ||
                    RouteUrl.BLOG === link.url
                      ? "noopener noreferrer"
                      : undefined
                  }
                  fontWeight="400"
                  fontSize="14px"
                >
                  {link.label}
                </ChakraLink>
              ) : (
                <ChakraLink
                  _hover={{ textDecoration: "none", opacity: 0.8 }}
                  whiteSpace="nowrap"
                  href={link.url}
                  fontWeight="400"
                  fontSize="14px"
                  target={
                    link.url === RouteUrl.FOOD ||
                    link.url === RouteUrl.RESTAURANT ||
                    link.url === RouteUrl.BLOG
                      ? "_blank"
                      : undefined
                  }
                  rel={
                    link.url === RouteUrl.FOOD ||
                    link.url === RouteUrl.RESTAURANT ||
                    link.url === RouteUrl.BLOG
                      ? "noopener noreferrer"
                      : undefined
                  }
                >
                  {link.label}
                </ChakraLink>
              )}
            </Flex>
          );
        })}
      </Flex>
      <Box w="160px" mx="2rem" h="100px">
        <Image w="full" h="full" fit="contain" src={Logo} alt="keep logo" />
      </Box>
      <Flex as="ul" gap="3rem" alignItems="center">
        {navbarLinks.slice(4).map((link, index) => {
          return (
            <Flex key={index} as="li">
              {link.type ? (
                <Button
                  onClick={() => navigate(RouteUrl.TICKETS)}
                  bg="green.400"
                  color="black"
                  colorScheme="green"
                >
                  Ticket
                </Button>
              ) : (
                <ChakraLink
                  _hover={{ textDecoration: "none", opacity: 0.8 }}
                  whiteSpace="nowrap"
                  href={link.url}
                  target={
                    link.url === RouteUrl.FOOD ||
                    link.url === RouteUrl.RESTAURANT ||
                    link.url === RouteUrl.BLOG
                      ? "_blank"
                      : undefined
                  }
                  rel={
                    link.url === RouteUrl.FOOD ||
                    link.url === RouteUrl.RESTAURANT ||
                    link.url === RouteUrl.BLOG
                      ? "noopener noreferrer"
                      : undefined
                  }
                  fontWeight="400"
                  fontSize="14px"
                >
                  {link.label}
                </ChakraLink>
              )}
            </Flex>
          );
        })}

        {localStorage.getItem("user_token") ? (
          <Flex as="li">
            <Button
              onClick={() => {
                localStorage.removeItem("user_token");
                localStorage.removeItem("userEmail");
                localStorage.removeItem("userId");
                navigate("/");
              }}
              bg="red.400"
              color="black"
              colorScheme="red"
            >
              Logout
            </Button>
          </Flex>
        ) : (
          <Flex as="li">
            <Button
              onClick={() => {
                navigate("/login");
              }}
              bg="green.400"
              color="black"
              colorScheme="green"
            >
              Login
            </Button>
          </Flex>
        )}

        <Box
          cursor="pointer"
          position="relative"
          onClick={() => navigate(RouteUrl.CHECKOUT)}
        >
          <Icon fontSize="3.2rem" as={AiOutlineShoppingCart} />
          <Flex
            borderRadius="full"
            w="30px"
            h="35px"
            alignItems="center"
            justifyContent="center"
            bg="white"
            top="0"
            right="-10px"
            p="2px"
            position="absolute"
          >
            <Text fontSize="14px" fontWeight="bold" color="primary.800">
              {cartQuantity}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default DesktopNav;
