import alcohol from "../../../../assets/prohibited/alcohol-icon.svg";
import beacker from "../../../../assets/prohibited/beacker-icon.svg";
import bottle from "../../../../assets/prohibited/bottle-icon.svg";
import chair from "../../../../assets/prohibited/chair-icon.svg";
import drone from "../../../../assets/prohibited/drone-icon.svg";
import drug from "../../../../assets/prohibited/drug-icon.svg";
import gun from "../../../../assets/prohibited/gun-icon.svg";
import knife from "../../../../assets/prohibited/knife-icon.svg";
import pets from "../../../../assets/prohibited/pets-icon.svg";
import ring from "../../../../assets/prohibited/ring-icon.svg";
import smoking from "../../../../assets/prohibited/smoking-icon.svg";
import spray from "../../../../assets/prohibited/spray-icon.svg";
import tank from "../../../../assets/prohibited/tank-icon.svg";
import tazer from "../../../../assets/prohibited/tazer-icon.svg";
import food from "../../../../assets/prohibited/foods.svg";

export const prohibited = [
  {
    name: "BOTTLE",
    img: bottle,
  },
  {
    name: "Alcohol",
    img: alcohol,
  },
  {
    name: "BEACKER",
    img: beacker,
  },
  {
    name: "CHAIR",
    img: chair,
  },
  {
    name: "DRONE",
    img: drone,
  },
  {
    name: "DRUG",
    img: drug,
  },
  {
    name: "GUN",
    img: gun,
  },
  {
    name: "KNIFE",
    img: knife,
  },
  {
    name: "PETS",
    img: pets,
  },
  {
    name: "JEWELRY",
    img: ring,
  },
  {
    name: "SMOKING",
    img: smoking,
  },
  {
    name: "SPRAY",
    img: spray,
  },
  {
    name: "TANK",
    img: tank,
  },
  {
    name: "TAZER",
    img: tazer,
  },
  {
    name: "FOOD",
    img: food,
  },
];

export const ParkListLabel = {
  TIKCET : "TIKCET",
  PARK : "PARK",
  PROHIBITED : "PROHIBITED",
}
  

  

