import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import reducer from "../reducers/products_reducer";
import { baseUrl } from "../utils/helpers";

import {
  GET_ECOMMERCE_BEGIN,
  GET_ECOMMERCE_SUCCESS,
  GET_ECOMMERCE_ERROR,
  GET_TICKET_BEGIN,
  GET_TICKET_SUCCESS,
  GET_TICKET_ERROR,
  GET_PICTURE_ERROR,
  GET_PICTURE_SUCCESS,
} from "../action";

const initialState = {
  ecommerce_loading: false,
  ecommerce_error: false,
  ecommerce_products: null,
  ticket_loading: false,
  ticket_error: false,
  ticket_products: null,
  picture_loading: true,
  picture_error: false,
  picture_content: null,
};

const ProductsContext = React.createContext();

export const ProductsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchEcommerceProducts = async (url) => {
    dispatch({ type: GET_ECOMMERCE_BEGIN });
    try {
      const response = await axios.get(url);
      const ecommerceProducts = response.data;

      dispatch({ type: GET_ECOMMERCE_SUCCESS, payload: ecommerceProducts });
    } catch (error) {
      dispatch({ type: GET_ECOMMERCE_ERROR });
    }
  };

  const getPictures = () => {
    axios
      .get(`${baseUrl}/WebSiteImage`)
      .then((res) => {
        dispatch({ type: GET_PICTURE_SUCCESS, payload: res.data });
      })
      .catch(() => {
        dispatch({ type: GET_PICTURE_ERROR });
      });
  };

  useEffect(() => {
    fetchEcommerceProducts(`${baseUrl}/Ecommerce`);
    getPictures();
  }, []);

  const fetchTicketProducts = async (url) => {
    dispatch({ type: GET_TICKET_BEGIN });
    try {
      const response = await axios.get(url);
      const ticketProducts = response.data;
      dispatch({ type: GET_TICKET_SUCCESS, payload: ticketProducts });
    } catch (error) {
      dispatch({ type: GET_TICKET_ERROR });
    }
  };

  useEffect(() => {
    fetchTicketProducts(`${baseUrl}/Ticket`);
  }, []);

  return (
    <ProductsContext.Provider value={{ ...state }}>
      {children}
    </ProductsContext.Provider>
  );
};
// make sure use
export const useProductsContext = () => {
  return useContext(ProductsContext);
};
