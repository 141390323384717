import {
  Button,
  Flex,
  HStack,
  Text,
  Box,
  useToast,
  chakra,
  Divider,
} from "@chakra-ui/react";
import React, { useEffect, useState, useContext, useCallback } from "react";
import axios from "axios";
import Wrapper from "../../components/Wrapper";
import { useNavigate } from "react-router-dom";
import CartSummary from "./components/CartSummary";
import CartContext from "../../context/cart_context";
import {
  baseUrl,
  formatPrice,
  getFullDayName,
  getUnactiveDays,
} from "../../utils/helpers";
import { Player } from "@lottiefiles/react-lottie-player";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import EmptyCart from "../../assets/images/empty_cart.json";
import Navbar from "../../components/navbar/Navbar";
import { AuthData } from "../../utils/userRequest";

import DatePicker from "react-datepicker";
import { useProductsContext } from "../../context/products_context";

const Checkout = () => {
  const { ticket_products: fetchedData, ecommerce_products: fetchedDataTwo } =
    useProductsContext();

  const { state, removeCart, clearCart } = useContext(CartContext);

  const [cartDetails, setCartDetaiils] = useState({
    allCarts: [],
    cartCat: {},
    subTotalCost: 0,
    vatCost: 0,
    totalCost: 0,
  });

  const [days, setDays] = useState([]);
  const [dayNum, setDayNum] = useState([]);

  const [productDiscounts, setProductsDiscounts] = useState([]);

  useEffect(() => {
    axios.get(`${baseUrl}/Product`).then((res) => {
      setProductsDiscounts(res?.data?.value);
    });
  }, []);

  useEffect(() => {
    var activeDays = fetchedData?.value[0]?.activeDays;

    if (activeDays) {
      var day = JSON.parse(fetchedData?.value[0]?.activeDays);
      var fullDays = day?.map((dy) => getFullDayName(dy));
      var dayNumbers = getUnactiveDays(fullDays);

      setDayNum(dayNumbers);

      setDays(day);
    }
  }, [fetchedData]);

  const userEmail = localStorage.getItem("userEmail");
  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const mobileNumber = localStorage.getItem("mobileNumber");

  const [config, setConfig] = useState({
    public_key: "FLWPUBK-fc5332ef1de292f6c60e8b55a6b37384-X",
    tx_ref: Date.now(),
    amount: 0,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: userEmail,
      phone_number: mobileNumber,
      name: `${firstName} ${lastName}`,
    },

    customizations: {
      title: "Park Vega Waterpark",
      description: "Welcome to Park Vega Waterpark",
      logo: "https://parkvegawaterpark.com/static/media/logo.93f4e29577a65e06553f.png",
    },
  });

  const [items, setItem] = useState([]);

  const handleFlutterPayment = useFlutterwave(config);

  const [emptyCart, setEmptyCart] = useState(true);

  const [startDate, setStartDate] = useState(new Date());

  const isWeekday = useCallback(
    (date) => {
      var fullDays = days?.map((dy) => getFullDayName(dy));
      var dayNum = getUnactiveDays(fullDays);

      var day = new Date(date).getDay();
      return !dayNum.includes(day.toString());
    },
    [days]
  );

  const toast = useToast();

  useEffect(() => {
    var allCartProduct = [],
      cartCategories = {},
      totalCost = 0,
      vatCost = 0,
      subTotalCost = 0,
      checkoutItems = [],
      allProds = [],
      ids = [];

    if (fetchedData && fetchedDataTwo) {
      if (fetchedData?.value) {
        var products = [];

        fetchedData?.value?.forEach((cat) => {
          products.push(...cat.products);
        });
        allProds = [...allProds, ...products];
      }

      if (fetchedDataTwo?.value) {
        var productsTwo = [];

        fetchedDataTwo?.value?.forEach((cat) => {
          productsTwo.push(...cat.products);
        });

        allProds = [...allProds, ...productsTwo];
      }

      ids = allProds.map((prod) => prod.id);

      Object.keys(state).forEach((cartItem) => {
        allCartProduct = [...allCartProduct, ...state[cartItem]];

        cartCategories[cartItem] = [];

        state[cartItem].forEach((item) => {
          if (!cartCategories[cartItem].includes(item.category)) {
            cartCategories[cartItem].push(item.category);
          }
        });
      });

      allCartProduct = allCartProduct?.filter((pro) => ids.includes(pro.id));

      Object.keys(state).forEach((cartItem) => {
        state[cartItem].forEach((item) => {
          if (!ids.includes(item.id)) {
            removeCart(cartItem, item);
          }
        });
      });

      allCartProduct.forEach((cartDetails) => {
        var cost = parseInt(cartDetails.cost) * cartDetails.quantity;
        var discountedItems = productDiscounts?.filter(
          (prod) => prod.id === cartDetails.id
        )[0]?.productDiscountResponses;
        var discount = 0;

        if (discountedItems) {
          var filteredPrice = discountedItems?.filter(
            (dis) =>
              parseInt(dis.startRange) <= parseInt(cartDetails.quantity) &&
              parseInt(dis.endRange) >= parseInt(cartDetails.quantity)
          );

          if (filteredPrice?.length > 0) {
            discount =
              cost * (parseInt(filteredPrice[0]?.discountPercentage) / 100);
          }
        }

        var discountedPrice = parseInt(cost) - parseInt(discount);

        subTotalCost += discountedPrice;
      });

      checkoutItems = allCartProduct.map((cart) => {
        var { id, quantity, cost, discount } = cart;
        var discountedPrice = parseInt(cost) * (parseInt(discount) / 100);
        var singleProductPrice = parseInt(cost) - discountedPrice;
        var totalCost = singleProductPrice * parseInt(quantity);

        return {
          productId: id,
          qty: quantity,
          cost,
          discount,
          totalCost,
        };
      });

      vatCost = subTotalCost * 0.075;
      totalCost = vatCost + subTotalCost;

      setCartDetaiils((prevState) => {
        return {
          ...prevState,
          allCarts: allCartProduct,
          cartCat: cartCategories,
          subTotalCost,
          vatCost,
          totalCost,
        };
      });

      setItem(checkoutItems);

      setConfig((prevState) => {
        return {
          ...prevState,
          amount: totalCost,
        };
      });
    }
  }, [state, fetchedData, removeCart, fetchedDataTwo, productDiscounts]);

  useEffect(() => {
    var cartAvailable = [];

    Object.keys(state).forEach((cartItem) => {
      if (state[cartItem]?.length > 0) {
        cartAvailable.push("true");
      } else {
        cartAvailable.push("false");
      }
    });

    if (cartAvailable.includes("true")) {
      setEmptyCart(false);
    } else {
      setEmptyCart(true);
    }
  }, [state]);
  const navigate = useNavigate();

  const StyledDatePicker = chakra(DatePicker);

  return (
    <>
      <Navbar />

      {emptyCart ? (
        <>
          <div className="empty-content vh-height">
            <div className="playing-anim">
              <Player
                className="animation"
                autoplay
                src={EmptyCart}
                style={{ height: "300px", width: "300px" }}
              />

              <h2>You have no carted item at the present moment</h2>
            </div>
          </div>
        </>
      ) : (
        <Wrapper cartCon maxWi="1200px">
          <Flex
            direction={{ sm: "row" }}
            bg="white"
            w="full"
            borderRadius={{ base: "0", sm: "0.5rem" }}
            overflow="hidden"
            marginTop={"6rem"}
            flexWrap="wrap"
          >
            <Flex justifyContent="space-between" w="full" pt={7} px="1.5rem">
              <Text fontSize="1.2rem" fontFamily="DINPro" fontWeight="bold">
                Please select your day of visit
              </Text>
              <Box d="flex">
                <StyledDatePicker
                  w="120px"
                  bg="green.400"
                  id="from"
                  fontWeight="600"
                  cursor="pointer"
                  zIndex={99}
                  px="10px"
                  filterDate={isWeekday}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </Box>
            </Flex>
            <Divider
              border="1px solid"
              bg="#6c6274"
              borderColor="#6c6274"
              my="1rem"
            />
            <Flex py="50px" pt={3} flexDirection="column" flex="1">
              {Object.keys(cartDetails?.cartCat).map((catItems, index) => {
                return (
                  <>
                    {cartDetails?.cartCat[catItems].length > 0 && (
                      <>
                        <Text
                          key={index}
                          opacity="1"
                          fontSize="1.4rem"
                          fontFamily="DINPro"
                          fontWeight="bold"
                          mb={3}
                        >
                          {catItems !== "ecommerce"
                            ? catItems.toUpperCase()
                            : "Consumables & Others".toUpperCase()}
                        </Text>

                        {cartDetails?.cartCat[catItems].map((cat) => {
                          var catData = cartDetails.allCarts.filter(
                            (cart) => cart.category === cat
                          );

                          return (
                            <CartSummary
                              title={cat}
                              productDiscounts={productDiscounts}
                              key={index}
                              data={catData}
                              category={catItems}
                            />
                          );
                        })}
                      </>
                    )}
                  </>
                );
              })}

              {/* Cart Total */}

              <Flex justifyContent="flex-end">
                <Box bg="primary.800" color="white" mt="8" p="12" mr="1.5">
                  <HStack justifyContent="flex-end" mt="8">
                    <Text
                      color="white"
                      opacity="0.7"
                      fontSize="1.5rem"
                      fontFamily="DINPro"
                      fontWeight="bold"
                    >
                      SUBTOTAL:
                    </Text>
                    <Text
                      color="#38C0F6"
                      fontSize="1.5rem"
                      fontFamily="DINPro"
                      fontWeight="bold"
                    >
                      {formatPrice(cartDetails.subTotalCost)}
                    </Text>
                  </HStack>
                  <HStack justifyContent="flex-end" mt="1" w="full">
                    <Text
                      color="white"
                      opacity="0.7"
                      fontSize="1.5rem"
                      fontFamily="DINPro"
                      fontWeight="bold"
                    >
                      VAT 7.5%:
                    </Text>
                    <Text
                      color="#38C0F6"
                      fontSize="1.5rem"
                      fontFamily="DINPro"
                      fontWeight="bold"
                    >
                      {formatPrice(cartDetails.vatCost)}
                    </Text>
                  </HStack>
                  <HStack justifyContent="flex-end" mt="8" w="full">
                    <Text
                      color="white"
                      opacity="0.7"
                      fontSize="1.5rem"
                      fontFamily="DINPro"
                      fontWeight="bold"
                    >
                      TOTAL:
                    </Text>
                    <Text
                      color="#38C0F6"
                      fontSize="1.5rem"
                      fontFamily="DINPro"
                      fontWeight="bold"
                    >
                      {formatPrice(cartDetails.totalCost)}
                    </Text>
                  </HStack>
                </Box>
              </Flex>

              {/* Payment Button */}
              <HStack justifyContent={"flex-end"} mt="2" mr="1.5">
                <Button
                  onClick={() => {
                    if (localStorage.getItem("user_token")) {
                      if (state?.tickets.length > 0) {
                        var day = new Date(startDate).getDay().toString();

                        if (dayNum.includes(day)) {
                          toast({
                            position: "top-right",
                            description: "Please select available days only",
                            status: "error",
                            duration: 5000,
                          });

                          return;
                        }

                        handleFlutterPayment({
                          callback: (response) => {
                            console.log(response);

                            const payload = {
                              customerId: localStorage.getItem("userId"),
                              dateOfVisit: startDate,
                              orderStatus: "Successful",
                              items,
                            };

                            AuthData("/Order", payload).then(() => {
                              clearCart().then(() => {
                                closePaymentModal(); // this will close the modal programmatically
                              });
                            });
                          },
                          onClose: () => {},
                        });
                      } else {
                        toast({
                          position: "top-right",
                          description: "Please buy a ticket before proceeding",
                          status: "error",
                          duration: 5000,
                        });
                      }
                    } else {
                      navigate("/login");
                    }
                  }}
                  colorScheme="green"
                  bg="green.400"
                >
                  {localStorage.getItem("user_token")
                    ? "Proceed to pay"
                    : "Login to make payment"}
                </Button>
                <Button
                  onClick={() => navigate(-1)}
                  colorScheme="blue"
                  bg="primary.800"
                >
                  Go Back
                </Button>
              </HStack>
              {/* end */}

              {/* Cart Total End */}
            </Flex>
          </Flex>
        </Wrapper>
      )}
    </>
  );
};

export default Checkout;
