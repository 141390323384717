//hooks
import { memo, Suspense, lazy } from "react";
import Navbar from "../../components/navbar/Navbar";
import { MotionFlex } from "../../framer";
import Ban from "./components/Ban";

//components
import Location from "./components/location/Location";
import PlanVisit from "./components/planVisit/PlanVisit";
import Services from "./components/services/Services";
import Social from "./components/socials/Social";
const Attractions = lazy(() => import("./components/attractions/Attractions"));
const Gallery = lazy(() => import("./components/gallery/Gallery"));

const Home = memo(() => {
  return (
    <MotionFlex w="full" direction="column" overflow="hidden">
      <Navbar />
      <Ban />
      <Suspense fallback={<p>loading attractions...</p>}>
        <Attractions />
      </Suspense>
      <PlanVisit />
      <Location />
      <Services />
      <Social />
      <Suspense fallback={<p>loading gallery...</p>}>
        <Gallery />
      </Suspense>
    </MotionFlex>
  );
});

export default Home;
