import {
  Stack,
  VStack,
  useBreakpointValue,
  Flex,
  Text,
  Image,
} from "@chakra-ui/react";
import heroImg from "../../../assets/images/ActivityPool.jpg";
import drop from "../../../assets/drop.png";
import { memo, useEffect, useState } from "react";
import { useProductsContext } from "../../../context/products_context";

const RestaurantHero = memo(() => {
  const { picture_content: pictures } = useProductsContext();
  const [eventHero, setEventHero] = useState(null);

  useEffect(() => {
    var hero = null;

    if (pictures) {
      hero = pictures?.value.filter(
        (picture) =>
          picture.pageName.toLowerCase() === "event" &&
          picture.tagType.toLowerCase() === "hero"
      )[0];
    }

    setEventHero(hero);
  }, [pictures]);

  return (
    <>
      <Flex
        w={"full"}
        h={"30rem"}
        backgroundImage={eventHero?.imageUrl ? eventHero?.imageUrl : heroImg}
        backgroundSize={"cover"}
        borderBottomRightRadius={"3rem"}
        borderBottomLeftRadius={"3rem"}
        // backgroundPosition={' center center'}
      >
        <VStack
          w={"full"}
          justify={"center"}
          px={useBreakpointValue({ base: 4, md: 8 })}
          background={"rgba(0,0,0,0.4)"}
          borderBottomRightRadius={"3rem"}
          borderBottomLeftRadius={"3rem"}
        >
          <Stack
            maxW={"3xl"}
            align={"flex-start"}
            spacing={6}
            alignItems={"center"}
          >
            <Text
              color={"white"}
              fontWeight={600}
              lineHeight={1.2}
              textAlign={"center"}
              fontFamily={"Berlin Sans, sans-serif"}
              fontSize={useBreakpointValue({ base: "7xl", md: "5rem" })}
            >
              <Image
                marginBottom={"-7rem"}
                marginRight={"8rem"}
                display={{ base: "none", md: "none", lg: "block" }}
                src={drop}
              />
              EVENTS
              <Image
                transform={"rotateY(180deg)"}
                marginTop={"-7rem"}
                marginLeft={"23rem"}
                display={{ base: "none", md: "none", lg: "block" }}
                src={drop}
              />
            </Text>
          </Stack>
        </VStack>
      </Flex>
    </>
  );
});

export default RestaurantHero;
