export const formatPrice = (number) => {
  return new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(number);
};

export const getPresentDate = (date) => {
  var presentDate = new Date(),
    dateObj = null,
    days = [
      {
        fullName: "Sunday",
        shortName: "Sun",
      },
      {
        fullName: "Monday",
        shortName: "Mon",
      },
      {
        fullName: "Tuesday",
        shortName: "Tue",
      },
      {
        fullName: "Wednesday",
        shortName: "Wed",
      },
      {
        fullName: "Thursday",
        shortName: "Thur",
      },
      {
        fullName: "Friday",
        shortName: "Fri",
      },
      {
        fullName: "Saturday",
        shortName: "Sat",
      },
    ],
    months = [
      {
        fullName: "January",
        shortName: "Jan",
      },
      {
        fullName: "February",
        shortName: "Feb",
      },
      {
        fullName: "March",
        shortName: "Mar",
      },
      {
        fullName: "April",
        shortName: "Apr",
      },
      {
        fullName: "May",
        shortName: "May",
      },
      {
        fullName: "June",
        shortName: "Jun",
      },
      {
        fullName: "July",
        shortName: "Jul",
      },
      {
        fullName: "August",
        shortName: "Aug",
      },
      {
        fullName: "September",
        shortName: "Sept",
      },
      {
        fullName: "October",
        shortName: "Oct",
      },
      {
        fullName: "November",
        shortName: "Nov",
      },
      {
        fullName: "December",
        shortName: "Dec",
      },
    ];

  if (date) {
    presentDate = new Date(date);
  }

  function period(hour) {
    var p = "AM";

    if (hour) {
      if (hour > 11) {
        p = "PM";
      }
    }

    return p;
  }

  function hour(hour) {
    var h = 12;

    if (hour) {
      h = hour;

      if (hour === 0) {
        h = 12;
      }

      if (hour > 12) {
        h = hour - 12;
      }
    }

    return h;
  }

  dateObj = {
    getDayNum: presentDate.getDate(),
    getDayFullName: days[presentDate.getDay()].fullName,
    getDayShortName: days[presentDate.getDay()].shortName,
    getMonthFullName: months[presentDate.getMonth()].fullName,
    getMonthShortName: months[presentDate.getMonth()].shortName,
    getFullYear: presentDate.getFullYear(),
    getShortYear: presentDate
      .getFullYear()
      .toString()
      .slice(presentDate.getFullYear().length - 2),
    get24Hours:
      presentDate.getHours().toString().length < 2
        ? `0${presentDate.getHours()}`
        : presentDate.getHours(),
    get12Hours:
      hour(presentDate.getHours()).toString().length < 2
        ? `0${hour(presentDate.getHours())}`
        : hour(presentDate.getHours()),
    getPeriod: period(presentDate.getHours()),
    getMinute:
      presentDate.getMinutes().toString().length < 2
        ? `0${presentDate.getMinutes()}`
        : presentDate.getMinutes(),
  };

  return dateObj;
};

export const changeToLowerCase = (value) => {
  var newValue = "";

  if (value) {
    var valueArr = value.toString().split("");

    valueArr.forEach((val) => {
      newValue += val.toLowerCase();
    });
  }

  return newValue;
};

export const getTodaysDate = (date) => {
  return `${getPresentDate(date).getDayNum}, ${
    getPresentDate(date).getMonthShortName
  } ${getPresentDate(date).getFullYear}`;
};

export const getFullDayName = (shortname) => {
  if (shortname) {
    var shortNameLower = changeToLowerCase(shortname);

    switch (shortNameLower) {
      case "m":
        return "Monday";

      case "mon":
        return "Monday";

      case "t":
        return "Tuesday";

      case "tue":
        return "Tuesday";

      case "w":
        return "Wednesday";

      case "wed":
        return "Wednesday";

      case "th":
        return "Thursday";

      case "thur":
        return "Thursday";

      case "f":
        return "Friday";

      case "fr":
        return "Friday";

      case "fri":
        return "Friday";

      case "st":
        return "Saturday";

      case "sat":
        return "Saturday";

      case "sun":
        return "Sunday";

      case "su":
        return "Sunday";

      default:
        return "";
    }
  } else {
    return "";
  }
};

export const dayNum = (day) => {
  var sentDay = changeToLowerCase(day);

  var days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  return days.indexOf(sentDay);
};

export const getUnactiveDays = (daysArr) => {
  var inActiveDays = [];

  var days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  if (daysArr && Array.isArray(daysArr)) {
    daysArr = daysArr.map((day) => changeToLowerCase(day));

    days.forEach((day, index) => {
      if (!daysArr.includes(day)) {
        inActiveDays.push(index.toString());
      }
    });
  }

  return inActiveDays;
};

export const getUniqueValues = () => {};

export const baseUrl = "https://parkvega-api.com/api";
