import React, { useState, useEffect } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { AuthData } from "../../utils/userRequest";
import Wrapper from "../../components/Wrapper";
import Navbar from "../../components/navbar/Navbar";

function Register(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [password, setPassword] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [loader, setLoader] = useState(false);
  const toast = useToast();

  const createAccount = async (e) => {
    setLoader(true);
    e.preventDefault();

    const payloads = {
      email: email,
      password: password,
      lastName: lastName,
      firstName: firstName,
      mobileNumber: mobileNumber,
    };

    try {
      const item = AuthData("/Auth/RegisterUser", payloads);

      item
        .then((res) => {
          toast({
            title: res?.data?.message,
            position: "top-right",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          navigate("/login");
        })
        .catch((err) => {
          let errMsg;
          if (err?.response?.data?.title === "One or more validation errors occurred.") {
            // If validation errors occurred, extract and display them
            const errors = err?.response?.data?.errors;
            const errorMessages = Object.keys(errors).map((key) => {
              return `- ${errors[key].join(", ")}`;
            });
            errMsg = errorMessages.join("\n");
          } else if (Array.isArray(err?.response?.data)) {
            // If error is an array, join the error messages
            errMsg = err?.response?.data.join(", ");
          } else {
            // If error is not an array and not validation errors, use the single error message
            errMsg = err?.response?.data?.title || err?.response?.data?.message;
          }
          toast({
            title: errMsg,
            position: "top-right",
            status: "error",
            duration: 8000,
            isClosable: true,
          });
        });
        
        
      setTimeout(() => setLoader(false), 1500);
    } catch (err) {
      setTimeout(() => setLoader(false), 1500);
    }
  };

  return (
    <>
      <Navbar />
      <Wrapper cartCon pt={75} maxWi="1200px">
        <Flex minH={"100vh"} align={"center"} justify={"center"}>
          <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
            <Stack align={"center"}>
              <Heading fontSize={"2xl"}>Login Now To Make Payment</Heading>
              <Text fontSize={"lg"} color={"gray.600"}>
                Have an account already ?{" "}
                <Link to="/login" color="blue.400">
                  Login Now
                </Link>
              </Text>
            </Stack>
            <Box
              rounded={"lg"}
              bg={useColorModeValue("white", "gray.700")}
              boxShadow={"lg"}
              p={8}
            >
              <form onSubmit={createAccount}>
                <Stack spacing={4}>
                  <FormControl isRequired id="fname">
                    {/* <FormLabel>FirstName</FormLabel> */}
                    <Input
                      type={"text"}
                      placeholder={"First name"}
                      label={"First Name"}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </FormControl>
                  <FormControl isRequired id="lname">
                    {/* <FormLabel>LastName</FormLabel> */}
                    <Input
                      type={"text"}
                      placeholder={"Last Name"}
                      label={"Last Name "}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </FormControl>
                  <FormControl isRequired id="email">
                    {/* <FormLabel>Email address</FormLabel> */}
                    <Input
                      type={"email"}
                      placeholder={"Email"}
                      label={"email"}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormControl>

                  <FormControl isRequired id="email">
                    {/* <FormLabel>Email address</FormLabel> */}
                    <Input
                      type={"number"}
                      placeholder={"Phone Number"}
                      label={"number"}
                      onChange={(e) => setMobileNumber(e.target.value)}
                    />
                  </FormControl>
                  <FormControl isRequired id="password">
                    {/* <FormLabel>Password</FormLabel> */}
                    <Input
                      type={"password"}
                      placeholder={"Password"}
                      label={"Password"}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </FormControl>
                  <Stack spacing={10}>
                    <Stack
                      direction={{ base: "column", sm: "row" }}
                      align={"start"}
                      justify={"space-between"}
                    ></Stack>
                    <Button
                      type="submit"
                      bg={"primary.800"}
                      color={"white"}
                      _hover={{
                        bg: "blue.500",
                      }}
                      isLoading={loader}
                    >
                      Sign Up
                    </Button>
                  </Stack>
                </Stack>
              </form>
            </Box>
          </Stack>
        </Flex>
      </Wrapper>
    </>
  );
}

export default Register;
