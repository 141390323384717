export const initialStateValues = {
  tickets: [],
  ecommerce: [],
};

const cartReducer = (state, action) => {
  if (action) {
    var { type, payload } = action;

    switch (type) {
      case "add":
        if (payload) {
          var { productType, product, quantity, category } = payload;

          if (parseInt(quantity) > 0) {
            if (productType === "ecommerce" || productType === "tickets") {
              if (productType === "ecommerce") {
                var ecommerceState = state?.ecommerce,
                  isAvailable = state?.ecommerce?.filter(
                    (ecommerce) => ecommerce.id === product?.id
                  );

                if (isAvailable?.length > 0) {
                  var newQuantity =
                    parseInt(isAvailable[0]?.quantity) + parseInt(quantity);

                  ecommerceState = ecommerceState.map((savedProduct) => {
                    if (savedProduct.id === product?.id) {
                      return {
                        ...savedProduct,
                        quantity: newQuantity.toString(),
                      };
                    } else {
                      return savedProduct;
                    }
                  });
                } else {
                  var newProductObj = {
                    ...product,
                    quantity: quantity.toString(),
                    category,
                  };

                  ecommerceState?.push(newProductObj);
                }

                return { ...state, ecommerce: ecommerceState };
              } else {
                var ticketState = state?.tickets,
                  availability = state?.tickets?.filter(
                    (tickets) => tickets.id === product?.id
                  );

                if (availability?.length > 0) {
                  var newQuant =
                    parseInt(availability[0].quantity) + parseInt(quantity);

                  ticketState = ticketState.map((savedProduct) => {
                    if (savedProduct.id === product?.id) {
                      return { ...savedProduct, quantity: newQuant };
                    } else {
                      return savedProduct;
                    }
                  });
                } else {
                  var newObj = {
                    ...product,
                    quantity: quantity.toString(),
                    category,
                  };

                  ticketState?.push(newObj);
                }

                return { ...state, tickets: ticketState };
              }
            } else {
              return state;
            }
          } else {
            return state;
          }
        } else {
          return state;
        }

      case "clear":
        return initialStateValues;

      case "remove":
        if (payload) {
          var { productType, product, quantity } = payload;

          if (productType === "ecommerce" || productType === "tickets") {
            if (productType === "ecommerce") {
              var newEcommerce = state?.ecommerce;
              var available = state?.ecommerce?.filter(
                (prod) => prod.id === product.id
              );
              var completelyRemoved = newEcommerce?.filter(
                (prod) => prod.id !== product.id
              );

              if (available?.length > 0) {
                if (quantity) {
                  var availableQuantity = available[0].quantity;

                  if (parseInt(availableQuantity) - parseInt(quantity) > 0) {
                    var newQuantity =
                      parseInt(availableQuantity) - parseInt(quantity);

                    newEcommerce = newEcommerce.map((savedProduct) => {
                      if (savedProduct.id === product?.id) {
                        return {
                          ...savedProduct,
                          quantity: newQuantity.toString(),
                        };
                      } else {
                        return savedProduct;
                      }
                    });
                  } else {
                    newEcommerce = completelyRemoved;
                  }
                } else {
                  newEcommerce = completelyRemoved;
                }
              }

              return { ...state, ecommerce: newEcommerce };
            } else {
              var newTickets = state?.tickets;
              var available = state?.tickets?.filter(
                (prod) => prod.id === product.id
              );
              var completelyRemoved = newTickets?.filter(
                (prod) => prod.id !== product.id
              );

              if (available?.length > 0) {
                if (quantity) {
                  var availableQuantity = available[0].quantity;

                  if (parseInt(availableQuantity) - parseInt(quantity) > 0) {
                    var newQuantity =
                      parseInt(availableQuantity) - parseInt(quantity);

                    newTickets = newTickets.map((savedProduct) => {
                      if (savedProduct.id === product?.id) {
                        return {
                          ...savedProduct,
                          quantity: newQuantity.toString(),
                        };
                      } else {
                        return savedProduct;
                      }
                    });
                  } else {
                    newTickets = completelyRemoved;
                  }
                } else {
                  newTickets = completelyRemoved;
                }
              }

              return { ...state, tickets: newTickets };
            }
          } else {
            return state;
          }
        } else {
          return state;
        }

      case "set":
        if (payload) {
          var { productType, product, quantity, category } = payload;

          if (productType === "ecommerce" || productType === "tickets") {
            if (payload.productType === "ecommerce") {
              var ecommerceState = state?.ecommerce,
                isAvailable = state?.ecommerce?.filter(
                  (ecommerce) => ecommerce.id === product?.id
                );

              if (isAvailable?.length > 0) {
                if (quantity !== 0) {
                  ecommerceState = ecommerceState.map((savedProduct) => {
                    if (savedProduct.id === product?.id) {
                      return { ...savedProduct, quantity: quantity.toString() };
                    } else {
                      return savedProduct;
                    }
                  });
                } else {
                  ecommerceState?.filter((prod) => prod.id !== product.id);
                }
              } else {
                if (quantity !== 0) {
                  var newProductObj = {
                    ...product,
                    quantity: quantity.toString(),
                    category,
                  };

                  ecommerceState?.push(newProductObj);
                }
              }

              return { ...state, ecommerce: ecommerceState };
            } else {
              var ticketState = state?.tickets,
                availability = state?.tickets?.filter(
                  (tickets) => tickets.id === product?.id
                );

              if (availability?.length > 0) {
                var newQuant = parseInt(quantity);

                ticketState = ticketState.map((savedProduct) => {
                  if (savedProduct.id === product?.id) {
                    return { ...savedProduct, quantity: newQuant.toString() };
                  } else {
                    return savedProduct;
                  }
                });
              } else {
                var newObj = {
                  ...product,
                  quantity: quantity.toString(),
                  category,
                };

                ticketState?.push(newObj);
              }

              return { ...state, tickets: ticketState };
            }
          } else {
            return state;
          }
        } else {
          return state;
        }

      case "localStorage":
        if (payload) {
          return payload.data;
        } else {
          return state;
        }

      default:
        return state;
    }
  } else {
    return state;
  }
};

export default cartReducer;
