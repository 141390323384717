export const colors = {
  primary: {
    50: "#d7f5ff",
    100: "#abe3ff",
    200: "#7ad3ff",
    300: "#48c8ff",
    400: "#1ac0ff",
    500: "#00b8f2", //the light blue
    600: "#007cb4",
    700: "#005082",
    800: "#006", //dark blue here
    900: "#000c20",
  },
  green: {
    50: "#eefee0",
    100: "#d7f6b7",
    200: "#beef8d",
    300: "#a5e962",
    400: "#97e549", //green color
    500: "#73c91d",
    600: "#589c13",
    700: "#3f700c",
    800: "#234404",
    900: "#081700",
  },
};
