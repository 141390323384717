import React from "react";
import { Heading, Text, Box, OrderedList, ListItem } from "@chakra-ui/react";
import Navbar from "../../components/navbar/Navbar";
import Wrapper from "../../components/Wrapper";

function Terms(props) {
  return (
    <>
      <Navbar />
      <Wrapper>
        <Box
          px={{ base: "1rem", sm: "10rem" }}
          marginTop="10rem"
          marginBottom="5rem"
        >
          <Heading>TERMS AND CONDITIONS</Heading>
          <Text marginTop="3rem">
            This web page represents a legal document and is the Terms and
            Conditions for the use of our Waterpark facility and website
            (www.parkvegawaterpark.com). By using our Waterpark facility and
            website, you agree to fully comply with and be bound by these Terms
            and Conditions as well as all Park rules each time you use.
            <Text />
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Definitions
              </Heading>
              The terms “us”, “we”, and “our” refer to Park Vega Waterpark
              Limited, this Website and Social Media handles. A “Guest(s)” is
              someone who uses our Waterpark facility or browses our website.{" "}
              <br /> <br />
              All text, information, graphics, design, and data offered through
              our website, social media handles or Services, are collectively
              known as our “Content”. <br /> <br />
              Any usage guidelines and precautions（hereinafter collectively
              referred to as “Rules”) presented by Park Vega Waterpark in
              connection with these Terms and Conditions shall, in addition to
              the Waterpark Rules and Regulations established by the Waterpark
              and kept at various points in the Waterpark, constitute a part of
              these Terms and Conditions.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Acceptance of Agreement
              </Heading>
              This Agreement is between a Guest and Park Vega Waterpark Limited.{" "}
              <br />
              THIS AGREEMENT CONTAINS WARRANTY DISCLAIMERS AND OTHER PROVISIONS
              THAT LIMIT OUR LIABILITY TO YOU. PLEASE READ THESE TERMS AND
              CONDITIONS CAREFULLY AND IN THEIR ENTIRETY, AS USING, ACCESSING,
              OUR WATERPARK FACILITY AND/OR BROWSING OUR WEBSITE CONSTITUTES
              ACCEPTANCE OF THESE TERMS AND CONDITIONS. IF YOU DO NOT AGREE TO
              BE BOUND TO EACH AND EVERY TERM AND CONDITION SET FORTH HEREIN,
              PLEASE DO NOT USE OUR WATERPARK FACILITY AND EXIT OUR WEBSITE
              IMMEDIATELY AND DO NOT USE, ACCESS, AND/OR BROWSE IT FURTHER.{" "}
              <br /> <br />
              Except as otherwise noted, this Agreement constitutes the entire
              and only Agreement between Guest(s) and Park Vega Waterpark and
              supersedes all other Agreements, representations, warranties, and
              understandings with respect to our Services, Website and the
              subject matter contained herein. However, for you to use our
              Website and/or Services, you may also be required to agree to
              additional terms and conditions. Those additional terms and
              conditions will be incorporated into this Agreement unless
              otherwise stated.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Arbitration
              </Heading>
              Any dispute, controversy or claim arising from or relating to this
              Agreement and/or our Service, excluding legal action taken by us
              to collect or recover damages for – or obtain any injunction
              relating to – facility use, website operations, intellectual
              property, and our Service, shall first be subject to resolution
              through consultation of the parties to such dispute, controversy
              or claim. Such consultation shall begin within seven (7) days
              after one Party hereto has delivered to the other Party involved a
              written request for such consultation. If within thirty (30) days
              following the commencement of such consultation the dispute cannot
              be resolved, the dispute shall be submitted to Mediation and or
              Conciliation before the Delta State Multi Door Court House or a
              Chartered Mediator and if parties are unable agree with the
              recommendations of Mediation, by Arbitration by an Arbitrator of
              the Chartered Institute of Arbitrators (Uk) Nigeria Branch or of
              the Nigerian Institute of Chartered Arbitrators (NICArb) upon the
              request of any Party with notice to the other Parties.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Limited License
              </Heading>
              Park Vega Waterpark grants you an nonexclusive, non transferable,
              revocable license to access and use our Services and Website
              strictly in accordance with this Agreement. Your use of our
              Website and Services is solely for internal, personal,
              non-commercial purposes unless otherwise provided for in this
              Agreement.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Legal Compliance
              </Heading>
              You agree to comply with all applicable domestic and international
              laws, statutes, ordinances, and regulations regarding your use of
              our Waterpark facility, Website, Content, Services, and any
              software provided therein.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Our Relationship to You
              </Heading>
              This Agreement in no way creates any agency, partnership, joint
              venture, or employee-employer or franchisor-franchisee
              relationship between you and Park Vega Waterpark Limited.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Errors, Corrections, and Changes
              </Heading>
              We do not represent or otherwise warrant that Park Vega Waterpark
              will be completely safe at all times or our website will be
              error-free; free from viruses or other harmful components. We do
              not represent or otherwise warrant that the Waterpark information
              available on or through our website will be correct, accurate,
              timely, or otherwise reliable.Park Vega Waterpark reserves the
              right at our sole discretion to change any equipment, content,
              software, and other items used in our Waterpark facility or
              contained in our website or Services at any time without notice.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Disclaimer
              </Heading>
              Our equipment and installations are manufactured by third parties.
              Accordingly, Park Vega Waterpark has no control over the
              manufacturing process or quality control of equipment and
              installations. In addition, Our Website and or Social Media
              handles may publish content supplied by third parties, Guests,
              Users, Advertisers, Merchants, or Sponsors. Park Vega Waterpark
              has no control over such content. For any equipment, installation,
              opinions, information or content expressed, manufactured or made
              available by third parties, Park Vega Waterpark does not guarantee
              the safety, accuracy, completeness, merchantability, or fitness
              for any particular purpose nor the legality of any equipment,
              installation or content provided by any of these parties. <br />{" "}
              <br />
              By purchasing any of our goods or services, user agrees to and is
              knowingly assuming any and all risk associated with using these
              products or services. <br /> <br />
              You hereby acknowledge that nothing contained in our Waterpark
              facility, Website or Social Media handle will constitute
              financial, investment, legal, and/or other professional advice and
              that no professional relationship of any kind is created between
              you and Park Vega Waterpark. You hereby agree that you will not
              make any financial, investment, legal, and/or other decision based
              in whole or in part on anything contained in our Website or
              Services.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Warranty Disclaimer
              </Heading>
              Park Vega Waterpark is not responsible or liable in any manner for
              any Content posted on our Website or in connection with our
              Services, or the behavior of guests who visit our Waterpark.
              Although we provide rules for guest conduct, we do not control and
              are not responsible for how guests ultimately dress, behave while
              using our facility, Website or social media and are not
              responsible for any offensive, inappropriate, obscene, unlawful,
              or otherwise objectionable behavior or content you may encounter
              using our website or Waterpark facility. Park Vega Waterpark is
              not responsible for the online or offline conduct of any User of
              our Website or Services. <br /> <br />
              Our Website or entire Waterpark facility or a segment of our
              Waterpark facility may be temporarily unavailable from time to
              time for maintenance or other reasons. Park Vega Waterpark assumes
              no responsibility for any error, omission, service interruption,
              deletion, defect, delay in operation or transmission,
              communication failure, theft or destruction, unauthorized access
              by guests. <br /> <br />
              Park Vega Waterpark is not responsible for any slide, electrical,
              structural, mechanical or technical malfunction or any other
              problems of any Waterpark facility equipment, installations,
              telephone network or service, computer system, server or provider,
              computer or mobile phone equipment, or software, or for any
              failure of payment terminals, email on account of technical
              problems or traffic congestion on the Internet, or for any
              combination thereof – including injury or damage to guests or any
              other person’s computer, mobile phone, or other hardware or
              software – related to or resulting from the use within the
              Waterpark facility or downloading of materials in connection with
              our Website. <br /> <br />
              Under no circumstances will Park Vega Waterpark be responsible for
              any loss or damage, including any loss or damage, personal injury,
              or death resulting from guests or anyone’s use of our Waterpark
              facility, website or Services, or any interactions between guests
              on/of our Website or waterpark, whether online or offline. <br />{" "}
              <br />
              Social Media or website reference to any products, services,
              processes, or other information by trade name, trademark,
              manufacturer, supplier, or otherwise does not constitute or imply
              endorsement, sponsorship, recommendation, or any affiliation with
              Park Vega Waterpark Limited by third parties. <br /> <br />
              THE INFORMATION, CONTENT, AND DOCUMENTS FROM OR THROUGH OUR
              WEBSITE ARE PROVIDED ‘AS-IS’, ‘AS AVAILABLE’, WITH ‘ALL FAULTS’,
              AND ALL EXPRESS OR IMPLIED WARRANTIES ARE DISCLAIMED (INCLUDING,
              BUT NOT LIMITED TO, THE DISCLAIMER OF ANY IMPLIED WARRANTIES OF
              MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE). OUR WEBSITE
              AND WATERPARK FACILITY MAY HAVE ERRORS, BUGS, PROBLEMS, OR OTHER
              LIMITATIONS. <br /> <br />
              PARK VEGA WATERPARK, INCLUDING ALL OUR AFFILIATES, HAS NO
              LIABILITY WHATSOEVER FOR GUEST(S) OF OUR WATERPARK FACILITY OR
              WEBSITE. PARK VEGA WATERPARK CANNOT GUARANTEE AND DOES NOT PROMISE
              ANY SPECIFIC RESULTS FROM THE USE OF OUR WATERPARK FACILITY OR
              WEBSITE. PARK VEGA WATERPARK DOES NOT REPRESENT OR WARRANT THAT
              OUR EQUIPMENT, BUILDINGS, INSTALLATIONS, CONTENT, SERVICES ARE
              ACCURATE, COMPLETE, RELIABLE, CURRENT, ERROR-FREE, FREE OR RISKS
              OR OTHER HARMFUL COMPONENTS. THEREFORE, YOU SHOULD EXERCISE
              CAUTION IN ITS USE. ALL RESPONSIBILITY OR LIABILITY FOR ANY
              DAMAGES OR BODILY HARM CAUSED BY THE USE OF PARK VEGA WATERPARK OR
              SOMEHOW ATTRIBUTED TO OUR WATERPARK, CONTENT OR SERVICES, IS
              DISCLAIMED <br /> <br />
              WITHOUT LIMITING THE FOREGOING, YOU UNDERSTAND AND AGREE THAT YOU
              USE PARK VEGA WATERPARK FACILITY AND WEBSITE AT YOUR OWN RISK AND
              THAT YOU WILL BE SOLELY RESPONSIBLE FOR YOUR USE THEREOF AND ANY
              INJURY, LOSS OF LIFE, BODILY HARM, PROBLEM, DAMAGES TO YOUR MOBILE
              DEVICE, LOSS OF DATA, THEFT, OR OTHER HARM OF ANY KIND THAT MAY
              RESULT. WE AND ALL OUR AFFILIATES ARE NOT LIABLE FOR ANY INDIRECT,
              SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES (INCLUDING DAMAGES
              FOR LOSS OF BUSINESS, LOSS OF PROFITS, LITIGATION, OR THE LIKE),
              WHETHER BASED ON BREACH OF CONTRACT, BREACH OF WARRANTY, TORT
              (INCLUDING NEGLIGENCE), SERVICE OR PRODUCT LIABILITY, OR
              OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE
              NEGATION AND LIMITATION OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL
              ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN YOU AND PARK VEGA
              WATERPARK. <br /> <br />
              OUR SERVICES AND WEBSITE WOULD NOT BE PROVIDED WITHOUT SUCH
              LIMITATIONS. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
              OBTAINED BY YOU FROM US THROUGH OUR WEBSITE, SOCIAL MEDIA HANDLES
              OR SERVICES WILL CREATE ANY WARRANTY, REPRESENTATION, OR GUARANTEE
              NOT EXPRESSLY STATED IN THIS AGREEMENT.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Limitation of Liability
              </Heading>
              IN NO EVENT WILL PARK VEGA WATERPARK LIMITED OR ITS DIRECTORS,
              EMPLOYEES, OR AGENTS BE LIABLE TO GUESTS OR ANY THIRD PERSON FOR
              ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR
              PUNITIVE DAMAGES, INCLUDING FOR ANY LOSS OF LIFE OR PROPERTY, LOST
              PROFITS OR LOST DATA ARISING FROM YOUR USE OF OUR WEBSITE,
              WATERPARK FACILITY, CONTENT, SERVICES. EVEN IF PARK VEGA WATERPARK
              IS AWARE OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, PARK
              VEGA WATERPARK LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND
              REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED
              TO THE AMOUNT PAID, IF ANY, BY YOU TO US FOR THE SERVICE ACCESSED
              JUST PRIOR TO THE EVENT GIVING RISE TO LIABILITY.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Use of Information
              </Heading>
              You grant Park Vega Waterpark a license to use the information and
              materials you post to our website. By posting, displaying,
              transmitting, performing, or otherwise distributing information or
              other content to our Website, you are granting Park Vega
              Waterpark, its officers, directors, employees, agents,
              consultants, representatives, and affiliates, a license to use the
              Content in connection with the marketing or operation of the
              business of Park Vega Waterpark Limited, its directors, employees,
              officers, affiliates, representatives, consultants, and agents,
              including, without limitation, a right to distribute, copy,
              transmit, publicly display, reproduce, translate, edit, and
              reformat Content. You understand and agree that you will not be
              compensated for any Content. By posting Content to our Website or
              Service, you warrant and represent that you own the rights to the
              Content or are authorized to post, display, distribute, perform,
              or transmit Content.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Unlawful Activity
              </Heading>
              We reserve the right to investigate complaints or reported
              violations of this Agreement and to take any action we deem
              appropriate, including, but not limited to, reporting any
              suspected unlawful activity to law enforcement officials,
              regulators, or other third parties and disclosing any information
              necessary or appropriate to such persons or entities relating to
              your profile, email addresses, usage history, posted materials, IP
              addresses, and traffic information.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Tickets
              </Heading>
              The ticket, if lost or stolen, will not be replaced nor will the
              purchase price be refunded. Admission to certain rides, shows,
              attractions, special events, and concerts may require an
              additional charge or be subject to pre-sold admission. Cabanas,
              priority seating, merchandise, food, and games may require
              additional fees. <br /> <br />
              Due to maintenance and other circumstances, certain rides and
              attractions (including new rides) may not be open to the public.{" "}
              <br /> <br />
              The bearer of this ticket grants Park Vega Waterpark the right to
              film, videotape, photograph the bearer on park property for any
              purpose without payment or consideration thereof. <br /> <br />
              Violations of these terms and conditions and park rules or
              applicable laws of the Federal Republic of Nigeria will result in
              expulsion without refund. <br /> <br />
              The park rules, in part, provide that inappropriate clothing or
              the use of offensive words, phrases, gestures or graphics are
              prohibited. Conduct that is disorderly, disruptive, may endanger
              others, or is in poor taste is prohibited. <br /> <br />
              BEARER, FOR THEMSELVES AND HIS/HER MINOR CHILDREN, DEPENDENTS,
              HEREBY AGREES TO THE FOREGOING TERMS AND FURTHER AGREES TO RELEASE
              AND HOLD HARMLESS PARK VEGA WATERPARK AND ITS AFFILIATED COMPANIES
              (THE COMPANIES) FROM AND TO WAIVE ANY AND ALL CLAIMS, CAUSE OF
              ACTION, DEMANDS, AND LIABILITIES FOR PERSONAL INJURY, THEFT
              PROPERTY DAMAGE, OR WRONGFUL DEATH, THAT MAY ARISE OUT OF THE OR
              IN CONNECTION WITH THE BEARER'S USE OR THAT ANY MINOR CHILDREN OR
              DEPENDENTS OF THE BEARER, OF THE PARK FACILITIES OR ANY OCCURRENCE
              ON THE PARK PREMISES, INCLUDING THOSE ARISING OUT OF THE
              COMPANIES' OR ANOTHER'S NEGLIGENCE. BEARER UNDERSTANDS THERE IS A
              RISK INHERENT IN THE ACTIVITIES AND THE PARK PREMISES AND BEARER,
              FOR THEMSELF AND HIS/HER MINOR CHILDREN OR DEPENDENTS, ACCEPTS
              RESPONSIBILITY AND ASSUMES ALL SUCH RISKS INHERENT IN THE
              PARTICIPATION IN THE ACTIVITIES, THE USE OF THE FACILITIES, THE
              CONDUCT OF OTHER PARTIES, AND THE AMENITIES AVAILABLE ON THE
              PREMISES.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Waterpark Entry Refusal
              </Heading>
              This Waterpark may refuse a guest to enter into the facility and
              or remove a guest already in the facility in the following cases:
              <OrderedList>
                <ListItem>
                  When the guest violates the stipulations of these Terms and
                  Conditions
                </ListItem>
                <ListItem> When the Waterpark is fully booked</ListItem>
                <ListItem>
                  {" "}
                  When a potential guest is deemed likely to break the law,
                  disrupt public order, or act contrary to public morals during
                  their stay.
                </ListItem>
                <ListItem>
                  {" "}
                  When the potential guest’s behaviour poses a significant
                  nuisance to other guests.
                </ListItem>
                <ListItem>
                  When the potential guest clearly has a contagious disease.
                </ListItem>
                <ListItem>
                  When the potential guest uses violence, threats, blackmail, or
                  intimidation to make unjust or unreasonable demands against
                  the Waterpark or its employees, or is deemed to have engaged
                  in any similar acts in the past.
                </ListItem>
                <ListItem>
                  {" "}
                  When the Waterpark is unable to accommodate guests due to
                  natural disaster, facility malfunction, or other inevitable
                  circumstances.
                </ListItem>
                <ListItem>
                  When the potential guest is intoxicated, exhibits or has
                  exhibited extremely abnormal behaviour that may pose a
                  nuisance to other guests, or in cases that fall under the
                  provisions of prefectural ordinances.
                </ListItem>
                <ListItem>
                  When the potential guest is extremely unsanitary or is wearing
                  extremely dirty clothes that may cause a nuisance to other
                  guests.
                </ListItem>
                <ListItem>
                  {" "}
                  When the potential guest conceals the fact that they have
                  purchased a Waterpark ticket for the purpose of making a
                  profit, either for themselves or for a third party etc.
                </ListItem>
                <ListItem>
                  When the potential guest does not comply with these Terms and
                  Conditions or with cancellation and payment policies when
                  making a reservation.
                </ListItem>
                <ListItem>
                  When a guest is a repeat customer with a past history of
                  violating our terms and conditions.{" "}
                </ListItem>
              </OrderedList>
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Prohibited Acts
              </Heading>
              Guests shall not engage in the following acts, either on their own
              or through the use of a third party.
              <OrderedList>
                <ListItem>
                  {" "}
                  Registering or providing false information at the point of
                  check-in.
                </ListItem>
                <ListItem>
                  Using fraudulent payment methods such as stolen credit cards,
                  fake bank transfers, presenting fake notes
                </ListItem>
                <ListItem>
                  Unauthorized acquisition or use of Park Vega Waterparks
                  membership benefits or personal information belonging to a
                  third party{" "}
                </ListItem>
                <ListItem>
                  Using the Waterpark for business purposes without permission
                  from the Waterpark
                </ListItem>
                <ListItem>
                  {" "}
                  Mass booking followed by mass cancellation or any similar acts
                </ListItem>
                <ListItem>
                  Repeatedly making and cancelling bookings without a
                  justifiable reason, or any similar acts
                </ListItem>
                <ListItem>
                  Impersonating the Waterpark or the Waterpark group, or any act
                  that could be mistaken for such
                </ListItem>
                <ListItem>
                  {" "}
                  Unauthorized access of systems or computers or any similar
                  acts
                </ListItem>
                <ListItem>
                  {" "}
                  Sending or uploading harmful computer programs or any similar
                  acts
                </ListItem>
                <ListItem>
                  Removal, defacing, or destroying equipment within the
                  Waterpark facilities, or any similar acts{" "}
                </ListItem>
                <ListItem>
                  Obstruction of business or damaging the reputation or brand of
                  the Waterpark or Waterpark group by making demands that exceed
                  socially acceptable norms, or slandering, defaming,
                  threatening, or harassing the Waterpark or its staff or
                  posting inflammatory remarks on social networking sites, or
                  any similar acts
                </ListItem>
                <ListItem>
                  {" "}
                  Violence, threats, extortion, or other coercive and
                  unreasonable demands against the Waterpark or its staff
                </ListItem>
                <ListItem>
                  Any acts that cause or risk causing inconvenience, damage, or
                  disadvantage to other guests, third parties, the Waterpark, or
                  the Waterpark group
                </ListItem>
                <ListItem>
                  Any acts that infringe or risk infringing on the copyrights,
                  trademarks, or other intellectual property rights, privacy
                  rights, personal rights, or any other rights of other guests,
                  third parties, the Waterpark, or the Waterpark group
                </ListItem>
                <ListItem>
                  Acts that violate or risk violating public order or law, or
                  any criminal acts
                </ListItem>
                <ListItem>
                  Displaying the power of an organized crime or cult group or
                  acts of aiding and abetting an organized crime or cult group
                </ListItem>
                <ListItem>
                  {" "}
                  Bringing in prohibited items, food and drinks into the
                  Waterpark.
                </ListItem>
                <ListItem>
                  Violation of any other provisions of these Terms and
                  Conditions
                </ListItem>
                <ListItem>
                  {" "}
                  Violation of any other rules in the Waterpark Rules and
                  Regulations
                </ListItem>
                <ListItem>
                  Any other acts deemed inappropriate by the Waterpark
                </ListItem>
              </OrderedList>
              The Waterpark shall be entitled to claim compensation from the
              guest for any damages incurred as a result of the acts in the
              preceding paragraph.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Parking Liability
              </Heading>
              The Waterpark consents to let guests park their vehicles in the
              parking space when a guest uses the Waterpark parking lot,
              regardless of whether the guest has deposited the keys with the
              Waterpark, and the Waterpark shall not be held liable for
              management of the vehicle.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Guest Liability
              </Heading>
              The guest shall compensate the Waterpark for any damages to the
              Waterpark caused by wilful misconduct or negligence on the part of
              the guest, including but not limited to facility repair costs and
              lost sales opportunities
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Exemptions
              </Heading>
              The Waterpark shall be exempt from liability when an exemption set
              forth in these Terms and Conditions or the Rules and Regulations
              applies.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Payments
              </Heading>
              You represent and warrant that if you are purchasing something
              from us, (i) any payment information you supply is true and
              complete, (ii) charges incurred by you will be honoured by your
              bank (iii) you will pay the charges incurred by you at the posted
              prices, including any applicable taxes, and (iv) if your initial
              payment method is dishonoured, you will still pay the incurred
              charges, including any surcharge we may incur due to the
              dishonoured payment.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Refund and Return Policy
              </Heading>
              Refund and Return PolicyAdmission Ticket purchases are
              non-refundable.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Indemnification
              </Heading>
              You agree to indemnify, defend, and hold us and our partners,
              agents, officers, directors, employees, subcontractors,
              successors, assigns, third-party suppliers of information and
              documents, attorneys, advertisers, product and service providers,
              and affiliates free from any liability, loss, claim, and expense,
              including reasonable attorney’s fees, related to your violation of
              this Agreement or use of our Website or Services.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Severability and Survival
              </Heading>
              Should any part of this Agreement be held invalid or
              unenforceable, that portion will be construed consistent with
              applicable law and the remaining portions will remain in full
              force and effect. To the extent that any Content is in conflict or
              inconsistent with this Agreement, this Agreement will take
              precedence. Our failure to enforce any provision of this Agreement
              will not be deemed a waiver of such a provision, nor of the right
              to enforce such a provision. Our rights under this Agreement will
              survive any termination of this Agreement. <br /> Even when a
              portion of these Terms and Conditions or other Rules is deemed
              invalid or revoked in relation to a particular guest, the Terms
              and Conditions and other Rules shall remain valid for all other
              guests.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Changes to Our Terms and Conditions
              </Heading>
              We reserve the right to change these Terms and Conditions at any
              time by posting these changes on our website. <br />
              These Terms and Conditions are subject to change without notice.
              Check the official website for the latest information. <br />
              Use of the Waterpark by the guest after changes to these Terms and
              Conditions constitutes consent to the amended agreement. <br />
              Notwithstanding the preceding paragraph, the provisions prior to
              the change shall apply to facility agreements concluded prior to
              the change of these Terms and Conditions.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Good Faith Negotiation
              </Heading>
              The Waterpark and guest shall negotiate in good faith to resolve
              any problems arising in connection with use of the Waterpark that
              cannot be resolved under these Terms and Conditions.
            </Text>
            <Text>
              <Heading
                fontWeight={"500"}
                fontSize="2rem"
                py="1rem"
                marginTop={"2rem"}
              >
                Governing Law
              </Heading>
              The agreement between the Park Vega Waterpark Limited and the
              guest(s) shall be governed by the laws of the Federal Republic of
              Nigeria.
            </Text>
          </Text>
        </Box>
      </Wrapper>
    </>
  );
}

export default Terms;
