import React, {useState, useEffect } from 'react'
import {
  Alert,
  AlertIcon
} from '@chakra-ui/react'


const MessageContext = React.createContext();

export const MessageProvider = ({children})=>{

  const [message, setMessage] = useState({
    type: "error",
    message: ""
  });

  useEffect(() => {

    if(message.message !== ""){

      var messageTimeout = setTimeout(() => {
        setMessage(prevMessage => {
          return({
            ...prevMessage,
            type: "error",
            message: ""
          })
        });
        
        clearTimeout(messageTimeout)
        
      }, 7000);

    }
  
  }, [message])

  const messageContainer = (
    <>

    {message.message !== "" && (
        <Alert status={message.type} position="fixed" top="0" left="0" zIndex={9}>
          <AlertIcon />
          {message.message}
        </Alert>
      )}
    </>
  );

  return(
    <MessageContext.Provider value={{message, setMessage, messageContainer}}>
      {children}
    </MessageContext.Provider>
  )

}

export default MessageContext;



