import {
  Center,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  Button,
  useToast,
  Box,
} from "@chakra-ui/react";
import { memo, useEffect, useState, useContext } from "react";
import { BiMinus } from "react-icons/bi";
import { BsPlus } from "react-icons/bs";
import CartContext from "../../../context/cart_context.js";
import { getFullDayName } from "../../../utils/helpers.js";

const TicketInput = memo(
  ({ activeDays, category, discountedProduct, pricePerDay, ticketDetails }) => {
    const [discount, setDiscount] = useState([]);
    const toast = useToast();
    const [newTicketDetails, setNewTicketDetails] = useState({});
    const [cartCategory, setCartCategory] = useState("");
    const similarCategory = ["group pass", "family pass"];
    const { state, addCart, removeCart, setCart } = useContext(CartContext);

    useEffect(() => {
      const product = discountedProduct.filter(
        (prod) => prod.id === ticketDetails.id
      );
      setDiscount(product[0]?.productDiscountResponses);
    }, [discountedProduct, ticketDetails]);

    useEffect(() => {
      setNewTicketDetails({ ...ticketDetails });
    }, [ticketDetails]);

    useEffect(() => {
      if (state?.tickets?.length > 0) {
        const categories = state.tickets.map((ticket) => ticket.category);
        const uniqueCategories = [...new Set(categories)];
        setCartCategory(uniqueCategories[0]);
      } else {
        setCartCategory("");
      }
    }, [state]);

    const getInitialQuantity = () => {
      const ticketInCart = state.tickets.find(
        (ticket) => ticket.id === ticketDetails.id
      );
      return ticketInCart ? ticketInCart.quantity : 0;
    };

    const [formData, setFormData] = useState({
      quantity: getInitialQuantity(),
    });

    const [price, setPrice] = useState(0);

    useEffect(() => {
      const discountPercentageArr = discount?.filter(
        (dis) =>
          parseInt(dis.startRange) <= parseInt(formData.quantity) &&
          parseInt(dis.endRange) >= parseInt(formData.quantity)
      );

      const amount = formData.quantity * pricePerDay;
      let totalDiscount = 0;

      if (discountPercentageArr?.length > 0) {
        totalDiscount =
          amount *
          (parseInt(discountPercentageArr[0]?.discountPercentage) / 100);
      }

      setPrice(amount - totalDiscount);
    }, [formData.quantity, pricePerDay, discount]);

    const handleDecrease = () => {
      if (formData.quantity <= 0) return;

      setFormData((prevState) => ({
        ...prevState,
        quantity: prevState.quantity - 1,
      }));
    };

    const handleIncrease = () => {
      setFormData((prevState) => ({
        ...prevState,
        quantity: prevState.quantity + 1,
      }));
    };

    const handleAddToCart = () => {
      if (formData.quantity === 0) return;

      addCart("tickets", newTicketDetails, formData.quantity, category)
        .then((res) => {
          toast({
            position: "top-right",
            description: res.response,
            status: "success",
            duration: 2000,
          });

          // Reset the quantity to 0
          setFormData((prevState) => ({
            ...prevState,
            quantity: 0,
          }));
        })
        .catch((error) => {
          console.log(error);
          toast({
            position: "top-right",
            description: "Failed to add to cart",
            status: "error",
            duration: 2000,
          });
        });
    };

    return (
      <>
        <InputGroup borderRadius="10px" bg="white" color="black" w="full">
          <InputLeftElement
            onClick={handleDecrease}
            role="button"
            children={<Icon color="#70D3F1" as={BiMinus} />}
            zIndex={2}
          />
          <Input
            w="full"
            value={formData.quantity}
            textAlign="center"
            type="number"
            placeholder="01"
            userSelect="none"
            zIndex={0}
            onChange={(e) => {
              var quantiValue = e.target.value.toString();
              if (
                cartCategory !== "" &&
                category.toLowerCase() !== cartCategory.toLowerCase() &&
                similarCategory.includes(category.toLowerCase())
              ) {
                toast({
                  position: "top-right",
                  description: `A similar type of ticket has been selected`,
                  status: "error",
                  duration: 5000,
                });

                return;
              }

              if (quantiValue === "") {
                quantiValue = "0";
              }

              if (parseInt(quantiValue) === 0) {
                removeCart("tickets", newTicketDetails);
              } else {
                setCart("tickets", newTicketDetails, parseInt(quantiValue));
              }

              setFormData((prevState) => ({
                ...prevState,
                quantity: parseInt(quantiValue),
              }));
            }}
          />
          <InputRightElement
            role="button"
            onClick={handleIncrease}
            children={<Icon color="#70D3F1" as={BsPlus} />}
            zIndex={2}
          />
        </InputGroup>
        <Center
          w="full"
          justifyContent="flex-end"
          px="5px"
          bg="white"
          color="black"
          h="40px"
          borderRadius="5px"
        >
          <Text userSelect="none">&#8358;{price.toLocaleString()}</Text>
        </Center>
        <Box mt={{ base: 4, md: 0 }}>
          <Button
            onClick={handleAddToCart}
            colorScheme="green"
            disabled={formData.quantity === 0}
          >
            Add to Cart
          </Button>
        </Box>
      </>
    );
  }
);

export default TicketInput;
