import React, { useCallback, useContext, useReducer } from 'react'

const UserContext = React.createContext();

const initialValue = {
    isLoggedin: false,
    userDetails: {}
}

const reducer = (state, action) => {

    switch(action.type){
        case "login":

            if(action.payload){

                return {
                    ...state,
                    isLoggedin: true,
                    userDetails: action.payload
    
                }
            }

            break;
        case "logout":

            return initialValue;


        default: 

            return state;
    }

}

export const UserContextProvider = ({children}) => {

    const [user, dispatch] = useReducer(reducer, initialValue);

    const saveUserLogin = useCallback((userDetails)=>{

        return new Promise((resolve, reject) => {
            if(userDetails){

                dispatch({type: "login", payload: userDetails});

                resolve({
                    response: "Log in successful"
                })

            }else{
                reject({
                    response: "Unable to save user credentials!"
                })
            }
        })
    }, [])

    const logOutUser = useCallback(() => {

        return new Promise((resolve, reject) => {
           

                dispatch({ type: "login"});

                resolve({
                    response: "Successfully Logged out"
                })

            
        })
    }, [])


  return (
    <>
    
          <UserContext.Provider value={{ user, saveUserLogin, logOutUser }}>
            {children}
        </UserContext.Provider>

    </>
  )
}


 const useUserContext = () => {
    return useContext(UserContext);
}

export default useUserContext;
