import { Button, Flex, VStack } from "@chakra-ui/react";
import Wrapper from "../../components/Wrapper";
import TicketCards from "./components/TicketCards";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { RouteUrl } from "../../utils/routeConstatnt";
import Navbar from "../../components/navbar/Navbar";

const Ticket = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <Wrapper cartCon bg="#F2F9FE" maxWi="1250px" py="50px" id="food">
        <VStack
          marginTop="6rem"
          userSelect="none"
          h="full"
          spacing="12"
          w="full"
        >
          <TicketCards isDiscount />
          <Flex
            w="full"
            px={{ base: "1rem", sm: "0" }}
            justifyContent="flex-end"
          >
            <Button
              size="lg"
              bg="green.400"
              colorScheme="green"
              onClick={() => navigate(RouteUrl.CHECKOUT)}
              shadow="lg"
            >
              Proceed To Checkout
            </Button>
          </Flex>
        </VStack>
      </Wrapper>
    </>
  );
};

export default Ticket;
