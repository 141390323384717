import React from "react";
import { Button, Flex, Link } from "@chakra-ui/react";
import { navbarLinks } from "./utils";
import { useNavigate, Link as RouteLink } from "react-router-dom";
import { RouteUrl } from "../../utils/routeConstatnt";
import { FaTicketAlt } from "react-icons/fa";

const MobileNav = ({ onClose }) => {
  const navigate = useNavigate();

  return (
    <Flex
      as="ul"
      flexDirection="column"
      w="full"
      py="4"
      gap="1rem"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      {navbarLinks.map((link, index) => {
        return (
          <Flex onClick={onClose} key={index} w="full" as="li">
            {link.type ? (
              <Button
                fontSize="1.5rem"
                p="10px 30px"
                bg="green.400"
                w="full"
                colorScheme="green"
                rightIcon={<FaTicketAlt />}
                onClick={() => navigate(RouteUrl.TICKETS)}
              >
                Ticket
              </Button>
            ) : (
              <>
                {!link.url.startsWith("/#") ? (
                  <Link
                    _hover={{ textDecoration: "none", opacity: 0.8 }}
                    whiteSpace="nowrap"
                    as={
                      RouteUrl.FOOD === link.url ||
                      RouteUrl.RESTAURANT === link.url ||
                      RouteUrl.BLOG === link.url
                        ? "a"
                        : RouteLink
                    }
                    to={link.url}
                    href={
                      RouteUrl.FOOD === link.url ||
                      RouteUrl.RESTAURANT === link.url ||
                      RouteUrl.BLOG === link.url
                        ? link.url
                        : undefined
                    }
                    target={
                      RouteUrl.FOOD === link.url ||
                      RouteUrl.RESTAURANT === link.url ||
                      RouteUrl.BLOG === link.url
                        ? "_blank"
                        : undefined
                    }
                    rel={
                      RouteUrl.FOOD === link.url ||
                      RouteUrl.RESTAURANT === link.url ||
                      RouteUrl.BLOG === link.url
                        ? "noopener noreferrer"
                        : undefined
                    }
                    w="full"
                    fontWeight="400"
                    fontSize="14px"
                  >
                    {link.label}
                  </Link>
                ) : (
                  <Link
                    _hover={{ textDecoration: "none", opacity: 0.8 }}
                    whiteSpace="nowrap"
                    href={link.url}
                    fontWeight="400"
                    fontSize="14px"
                    w="full"
                    target={
                      link.url === RouteUrl.FOOD ||
                      link.url === RouteUrl.RESTAURANT ||
                      link.url === RouteUrl.BLOG
                        ? "_blank"
                        : undefined
                    }
                    rel={
                      link.url === RouteUrl.FOOD ||
                      link.url === RouteUrl.RESTAURANT ||
                      link.url === RouteUrl.BLOG
                        ? "noopener noreferrer"
                        : undefined
                    }
                  >
                    {link.label}
                  </Link>
                )}
              </>
            )}
          </Flex>
        );
      })}
      {localStorage.getItem("user_token") ? (
        <Flex as="li">
          <Button
            onClick={() => {
              localStorage.removeItem("user_token");
              localStorage.removeItem("userEmail");
              localStorage.removeItem("userId");
              navigate("/");
            }}
            bg="red.400"
            color="black"
            colorScheme="red"
          >
            Logout
          </Button>
        </Flex>
      ) : (
        <Flex as="li">
          <Button
            onClick={() => {
              navigate("/login");
            }}
            bg="green.400"
            color="black"
            colorScheme="green"
          >
            Login
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default MobileNav;
