import React, { useEffect, useState } from "react";
import {
  Flex,
  Heading,
  Text,
  VStack,
  Grid,
  HStack,
  Tag,
  Box,
} from "@chakra-ui/react";
import OneDay from "./OneDay";
import TicketInput from "./TicketInput";
import { useProductsContext } from "../../../context/products_context";

import { baseUrl, getFullDayName } from "../../../utils/helpers";
import axios from "axios";

const TicketCards = ({ children, isDiscount, ...rest }) => {
  const {
    ticket_loading: loading,
    ticket_error: error,
    ticket_products: fetchedData,
  } = useProductsContext();

  const [dates, setDates] = useState([]);
  const [productDiscounts, setProductsDiscounts] = useState([]);

  useEffect(() => {
    axios.get(`${baseUrl}/Product`).then((res) => {
      setProductsDiscounts(res?.data?.value);
    });
  }, []);

  return (
    <>
      {loading && <h2>...Loading</h2>}
      {error && <h2>Something went wrong</h2>}
      {fetchedData?.value?.map((ticket) => {
        var productDate = dates?.filter((dates) => dates.id === ticket.id)[0]
          ?.date;

        var activeDays = JSON.parse(ticket.activeDays);

        return (
          <Box key={ticket?.id}>
            {ticket?.products?.length > 0 && (
              <Flex
                key={ticket.id}
                direction={{ base: "column", lg: "row" }}
                bg="white"
                boxShadow="lg"
                {...rest}
                w="50"
                borderRadius="10px"
                h="auto"
                px={{ base: "1rem", sm: "0" }}
                position="relative"
              >
                <Flex
                  p="2rem"
                  w="full"
                  direction="column"
                  flex="1"
                  h="full"
                  justifyContent="center"
                >
                  <Heading
                    color="#38C0F6"
                    fontSize="1.5rem"
                    fontFamily="Berlin Sans"
                    fontWeight="600"
                    mb="2"
                  >
                    {ticket.title}
                  </Heading>
                  <VStack spacing="0" alignItems="flex-start" w="full">
                    <Text color="#83b2c1">
                      {ticket.description} <br /> <br />
                      This Ticket is available for use on the following day:{" "}
                    </Text>
                  </VStack>

                  <HStack spacing="2" w="full" mt="4">
                    {activeDays?.map((days, key) => {
                      return (
                        <Tag
                          size="md"
                          key={key}
                          variant="solid"
                          colorScheme="blue"
                        >
                          {getFullDayName(days)}
                        </Tag>
                      );
                    })}
                  </HStack>
                </Flex>
                <Flex
                  direction="column"
                  justifyContent="flex-start"
                  flex="1"
                  bg="primary.800"
                  borderTopRightRadius="10px"
                  borderBottomRightRadius="10px"
                  h="auto"
                  w="full"
                >
                  <OneDay
                    days={activeDays}
                    setDates={setDates}
                    ticket={ticket}
                  />
                  {ticket.products?.map((product, index) => {
                    return (
                      <VStack
                        key={index}
                        direction="column"
                        px={{ base: "1rem", sm: "2rem" }}
                        py="5px"
                        color="white"
                        mb="5px"
                      >
                        <Grid
                          w="full"
                          placeItems="center"
                          gridColumnGap={{ base: ".5rem", sm: "1rem" }}
                          gridTemplateColumns={{
                            base: "1.2fr 1fr 1fr",
                            sm: "1.5fr 1fr 1fr 1fr",
                          }}
                        >
                          <Box w="full" position="relative">
                            <Text
                              w="full"
                              textAlign="center"
                              p="5px 10px"
                              // bg="#1E2276"
                            >
                              {product.name}
                            </Text>
                          </Box>
                          <TicketInput
                            ticketDetails={product}
                            pricePerDay={product.cost}
                            category={ticket.title}
                            date={productDate}
                            activeDays={activeDays}
                            discountedProduct={productDiscounts}
                          />
                        </Grid>
                      </VStack>
                    );
                  })}
                </Flex>
              </Flex>
            )}
          </Box>
        );
      })}
    </>
  );
};

export default TicketCards;
